var EPK = EPK || {};
EPK.Member = EPK.Member || {};
EPK.MemberDisp = EPK.MemberDisp || {};

EPK.Member = function(){
	var cancelUrl;
	var receiveId;
	var pharmacyId;
	var couponId;
	var reserveId;
	var cancelReasonUrl;
	var statusChangeUrl;
	var deleteCouponUrl;
	var usedCouponUrl;
	var viewCouponUrl;
	var deleteBookmarkShopLink;
	var deleteBookmarkShopBox;
	var shopIdList;
	var isDeliveryReserve;
	this.selectedCouponData = null;
	this.couponCountdownTimerList = [];
	this.fadeUpFooter = document.getElementById('fade-up-footer');
	this.showMoreShopCouponsActiveFlag = 1;
	this.webCmCheckedList = []
};

EPK.Member.isIntervalObserved = false;

EPK.Member.checkinexpSlider = null;

EPK.Member.prototype.callReserveCancel = function(event) {

	var _this = this;
	$.ajax({
		url: this.cancelUrl,
		type: 'POST',
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		//this.showCancelReason(event);
	}).fail( function(data) {
		this.showCancelFail(event);
	});
};

EPK.Member.prototype.changeReserveStatus = function() {
	var _this = this;

	var $loadFilter = $('.loading-filter');
	$('body').prepend($loadFilter);
	$loadFilter.show();

	$.ajax({
		type: 'POST',
		url: this.statusChangeUrl,
		cache: false,
		dataType : "json",
		context: _this,
		data: {
			"reserve_id": this.reserveId,
			"pharmacy_id": this.pharmacyId
		}
	}).done(function(){
		location.href = '/member/reserve?isChangeStatus=1';
	}).always(function() {
		$loadFilter.hide();
	}).fail( function(data) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#status-change-fail-modal'},
			type: 'inline',
			modal: true
		}, 0);
	});
};

EPK.Member.prototype.bindShowPassword = function(event) {
	$("#password_show").on("change",function(event){
		var $target = $(event.target);
		var checked = $target.prop("checked");
		if(checked == true) {
			$("#password-confirm").prop("type","text");
			$("#password").prop("type","text");
		} else {
			$("#password-confirm").prop("type","password");
			$("#password").prop("type","password");
		}
	});
};


EPK.Member.prototype.validate = function($form){
	var val = $form.find("input:radio[name='reason']:checked").val();
	return val;
};

EPK.Member.prototype.ShowInputLength = function(strVal){
	var $textarea = $('#cancel_message');
	var $count = $('.num');
	var thisValueLength = '';

	thisValueLength = $textarea.val().replace(/\s+/g,'').length;
	$count.text(thisValueLength);
}

EPK.Member.prototype.showCancelOpen = function(event){
	var targetElem = $(event.currentTarget);

	this.cancelUrl = targetElem.data("cancel_url");
	this.receiveId = targetElem.data("receive_id");
	this.pharmacyId = targetElem.data("pharmacy_id");

	if (this.isDeliveryReserve) {
		this.cancelReasonUrl = '/delivery/cancel/reason?pharmacy_id='+this.pharmacyId+'&reserve_id='+this.receiveId;
	} else {
		this.cancelReasonUrl = '/shoho/resend/cancel-reason?pharmacy_id='+this.pharmacyId+"&receive_id="+this.receiveId;
	}

	$.magnificPopup.open({
		items: {src: '#cancel-modal'},
		type: 'inline',
		modal: true
	}, 0);
};

EPK.Member.prototype.showReceiveConfirmOpen = function(event){
	var targetElem = $(event.currentTarget);
	this.reserveId = targetElem.data("reserve_id");
	this.pharmacyId = targetElem.data("pharmacy_id");
	this.statusChangeUrl = '/shoho/resend/receive-status-change?pharmacy_id='+this.pharmacyId+"&reserve_id="+this.reserveId;

	$.magnificPopup.open({
		items: {src: '#acceptance-modal'},
		type: 'inline',
		modal: true
	}, 0);
};

EPK.Member.prototype.showCancelReason = function(event){
	$.magnificPopup.close();
	$.magnificPopup.open({
		items: {src: '#cancel-reason-modal'},
		type: 'inline',
		modal: true
	}, 0);
};

EPK.Member.prototype.showCancelReasonForDeliveryReserve = function(event){
	$.magnificPopup.close();
	$.magnificPopup.open({
		items: {src: '#cancel-reason-delivery-modal'},
		type: 'inline',
		modal: true
	}, 0);
};

EPK.Member.prototype.showCancelFail = function () {
	$.magnificPopup.close();
	$.magnificPopup.open({
		items: {src: '#cancel-fail-modal'},
		type: 'inline',
		modal: true
	}, 0);
};

EPK.Member.prototype.openPopup = function(src) {
	$.magnificPopup.close();
	$.magnificPopup.open({
		items: {src: src},
		type: 'inline',
		modal: true,
	}, 0);
}

EPK.Member.prototype.sendCancelReason = function(event){

	var _this = this;
	var $form = $('#cancel-reason-form');
	//event.preventDefault();

	var validated = this.validate($form);
	if(validated){
		$.ajax({
			url: this.cancelReasonUrl,
			type: 'POST',
			data: {
				"reason": $($form[0].reason).filter(":checked").val(),
				"free_text": $($form[0].free_text).val(),
				"cancel_csrf_token": $($form[0].cancel_csrf_token).val(),
				"controller_name": $($form[0].controller_name).val()
			},
			cache: false,
			dataType : "json",
			context: _this
		}).done(function(data, textStatus, jqXHR){
			// console.log("cancel reason success");
			// 理由確認モーダルの表示
			_this.callReserveCancel(event);
			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {src: '#cancel-complete-modal'},
				type: 'inline',
				modal: true
			}, 0);

		}).fail( function(data) {
			// console.log("cancel fail");
			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {src: '#cancel-reason-fail-modal'},
				type: 'inline',
				modal: true
			}, 0);
		});
	}else{
		$form.find('.error-text').show();
		var pos = $(".error-text").position();
		$(".mypage-modal-content02").scrollTop(pos.top);
	}
};

EPK.Member.prototype.executeReserveCancelReason = function(event) {
	var _this = this;
	var $form = $('#cancel-info-form');
	this.pharmacyId = $($form[0].pharmacy_id).val();
	this.reserveId = $($form[0].reserve_id).val();
	var reason = $($form[0].reason).val();
	var status_change = 0;
	this.cancelReasonUrl = '/shoho/resend/cancel-reason?pharmacy_id='+this.pharmacyId+'&receive_id='+this.reserveId;
	this.cancelUrl = '/shoho/resend/cancel?pharmacy_id='+this.pharmacyId+'&receive_id='+this.reserveId;

	if(reason == 5) {
		status_change = 1;
	}

	$.ajax({
		type: 'POST',
		url: this.cancelReasonUrl,
		cache: false,
		dataType : "json",
		context: _this,
		data: {
			"reason": reason,
			"free_text": $($form[0].free_text).val(),
			"controller_name":$($form[0].controller_name).val(),
			"status_change": status_change,
		}
	}).done(function(){
		if(reason == 5) {
			this.statusChangeUrl = '/shoho/resend/receive-status-change?pharmacy_id='+this.pharmacyId+"&reserve_id="+this.reserveId;
			_this.changeReserveStatus();
		} else {
			_this.callReserveCancel(event);
			$.magnificPopup.open({
				items: {src: '#complete-cancel-modal'},
				type: 'inline',
				modal: true
			}, 0);
		}
	}).fail(function(data) {
		$.magnificPopup.open({
			items: {src: '#status-change-fail-modal'},
			type: 'inline',
			modal: true
		}, 0);
	});
};

EPK.Member.prototype.sendCancelReasonForDeliveryReserve = function(event){
	var _this = this;
	var $form = $('#cancel-reason-delivery-form');
	//event.preventDefault();

	var validated = this.validate($form);
	if(validated){
		$.ajax({
			url: this.cancelReasonUrl,
			type: 'POST',
			data: {
				"reason": $($form[0].reason).filter(":checked").val(),
				"free_text": $($form[0].free_text).val()
			},
			cache: false,
			dataType : "json",
			context: _this
		}).done(function(data, textStatus, jqXHR){
			// console.log("cancel reason success");
			// 理由確認モーダルの表示
			_this.callReserveCancel(event);
			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {src: '#cancel-complete-modal'},
				type: 'inline',
				modal: true
			}, 0);

		}).fail( function(data) {
			// console.log("cancel fail");
			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {src: '#cancel-reason-fail-modal'},
				type: 'inline',
				modal: true
			}, 0);
		});
	}else{
		$form.find('.error-text').show();
		var pos = $(".error-text").position();
		$(".mypage-modal-content02").scrollTop(pos.top);
	}
};

/**
 * 「もっとみる」制御
 */
EPK.Member.loadMoreList = function (event, index) {
	if (index == 3){
		var idx = '';
	}else{
		var idx = index;
	}
	var targetUrl = $(event.target).closest(".btn-loadMore" + idx).data("loadmore-url");
	var original_event = event;

	$('.btn-loadMore' + idx).prop('disabled', true);

	$.ajax({
		url: targetUrl,
		type: 'GET',
		cache: false,
		dataType : "html",
		context: original_event
	}).done(function(data, textStatus, jqXHR){
//		console.log(data);
		$(".btn-loadMore" + idx).remove(0);
		$("#area-loadMore" + idx).append(data);

		if (index == 3){
			//予約ボタン表示・受取時間表示非同期処理
			if(EPK.MemberDisp.isSetReserveButton){
				EPK.Member.setReserveButton();
			} else {
				if(EPK.MemberDisp.isSetHospitalReserveButton){
					EPK.Member.renderRemoteColutionBtn();
				}
				if(EPK.MemberDisp.isSetHospitalOutpatientReserveButton){
					EPK.Member.renderRemoteOutpatientBtn();
				}
			}

			// 駐車場入口写真リンククリック
			$('.parking-photo').off('click');
			$('.parking-photo').on('click', function(event){
				EPK.Member.showParkingPhotoOpen(event);
			});

			// 駐車場到着をお知らせするボタンクリック
			$('.send-parking-reach-btn').off('click');
			$('.send-parking-reach-btn').on('click', function(event){
				EPK.Member.showSendParkingReachOpen(event);
			});

			// #11987 予約ステータス、支払い履歴の追加（薬局・病院）受け取り完了をクリック
			var receiveBtn = document.getElementsByClassName('remote_shoho_status_complete_btn');
			if (receiveBtn.length > 0) {
				[].forEach.call(receiveBtn,function (elem) {
					elem.removeEventListener('click',EPK.Member.receiveCompleate);
					elem.addEventListener('click',EPK.Member.receiveCompleate);
				})
			}

			$('.remote_outpatient_cancel_btn').each(function(i,e) {
				$(e).off('click.cancelbtn').on('click.cancelbtn', function(event) {
					EPK.Member.sendCancelForRemoteOutpatient(event);
				});
			});

		}else if (index == 2){
			//予約ボタン表示・受取時間表示非同期処理
			if(EPK.MemberDisp.isSetReserveButton){
				EPK.Member.setReserveButton();
			} else {
				if(EPK.MemberDisp.isSetHospitalReserveButton){
					EPK.Member.renderRemoteColutionBtn();
				}
				if(EPK.MemberDisp.isSetHospitalOutpatientReserveButton){
					EPK.Member.renderRemoteOutpatientBtn();
				}
			}
			if(EPK.MemberDisp.isSetSatisfactionAnswerArea){
				if (EPK.MemberDisp.isMobile) {
					EPK.Member.setSatisfactionAnswerAreaForSP();
				} else {
					EPK.Member.setSatisfactionAnswerArea();
				}
			}

		}

		if (EPK.MemberDisp.reserveNowCheckinList && EPK.MemberDisp.isMobile) {
			// 位置情報確認・チェックインAPI実行
			EPK.Member.checkin(false);
		}
	}).fail( function(data) {
		console.log("load more fail");
	});
};

EPK.Member.showConfirmDeleteBookmark = function (event) {
	this.deleteBookmarkShopBox = $(event.target).closest(".shop-box");
	this.deleteBookmarkShopLink = this.deleteBookmarkShopBox.data("shop-link");

	$.magnificPopup.close();
	$.magnificPopup.open({
		items: {src: '#modal-bookmark-delete-confirm'},
		type: 'inline',
		modal: true
	}, 0);
};

// 店舗ごとのクーポン情報を非同期取得（ポイントクーポン対応にて追加）
EPK.Member.prototype.showMoreShopCoupons = function(event){

	var _this = this;
	if(_this.showMoreShopCouponsActiveFlag == 0) {
		return false;
	}
	_this.showMoreShopCouponsActiveFlag = 0;

	var objContainer = $('#container-shop-couponlist');
	var objCountNextPage = $('#coupon-count-on-next-page');

	var objEvent = $('.more-coupon-btn a.coupon-more-view');
	var objEventContainer = $('.more-coupon-btn');
	var url = objEvent.attr('target-url');
	var targetPage = objEvent.attr('target-page');
	var paged = objEvent.attr('paged');

	if(paged==targetPage){
		return;
	}

	var loadingIcon = $('#coupon-more-view-loader');
	loadingIcon.show();

	$.ajax({
		type: 'GET',
		url: url,
		dataType : "html",
		cache: false,
		data: {
			more: targetPage
		}
	}).done(function(data){
		if(data) {
			objContainer.append(data);
			_this.coupon_init();
			loadingIcon.hide();

			if (_this.selectedCouponData) {
				// クーポン選択中の場合、追加読み込み店舗のクーポンを選択できないように制御
				var disableCheckboxList = document.querySelectorAll('.shop-couponlist input[type="checkbox"]:not(.' + Object.keys(_this.selectedCouponData)[0] + ')');
				[].forEach.call(disableCheckboxList, function (checkbox) {
					checkbox.disabled = true;
				});
			}

			var selectorHidden = '#coupon-count-on-page-' + (parseInt(targetPage) + 1);
			var countOnNextPage = $(selectorHidden).val();
			if(!countOnNextPage) {
				objEventContainer.hide();

			}else if(countOnNextPage > 0) {
				objEventContainer.show();
				objEvent.attr('target-page', parseInt(targetPage) + 1);
				objEvent.attr('paged', targetPage);
				objCountNextPage.html(countOnNextPage);
				_this.showMoreShopCouponsActiveFlag = 1;
			}else {
				objEventContainer.hide();
			}

		}
	}).fail(
		function(data) {
		}
	);
};

EPK.Member.prototype.showCouponDeleteOpen = function(event, modalId){
	this.couponId = $(event.target).data("id");
	this.deleteCouponUrl = '/coupon/delete/'+this.couponId;
	$.magnificPopup.open({
		items: {src: modalId},
		type: 'inline',
		modal: true
	}, 0);
};

EPK.Member.prototype.deleteCoupon = function(event, modalId){

	var _this = this;
	$.ajax({
		url: this.deleteCouponUrl,
		type: 'POST',
		data: {},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data && data.result && data.result.status && data.result.status == 1){
			$.magnificPopup.open({
				items: {src: modalId},
				type: 'inline',
				callbacks: {
					close: function(){
						location.reload();
					}
				},
				modal: true
			}, 0);
		} else {
			$.magnificPopup.open({
				items: {src: '#coupon-delete-error'},
				type: 'inline',
				callbacks: {
					close: function(){
						location.reload();
					}
				},
				modal: true
			}, 0);
			if(data && data.result && data.result.error && data.result.error != ""){
				$('#coupon-delete-error .text').html(data.result.error)
			}
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#coupon-delete-error'},
			type: 'inline',
			callbacks: {
				close: function(){
					location.reload();
				}
			},
			modal: true
		}, 0);
	});
};

EPK.Member.prototype.showCouponUseOpen = function(event){
	this.couponId = $(event.target).data("id");
	this.usedCouponUrl = '/coupon/used/'+this.couponId;
	$.magnificPopup.open({
		items: {src: '#coupon-use-check'},
		type: 'inline',
		modal: true
	}, 0);
};

EPK.Member.prototype.useCoupon = function(event){

	var _this = this;
	$.ajax({
		url: this.usedCouponUrl,
		type: 'POST',
		data: {},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data && data.result && data.result.status && data.result.status == 1){
			$.magnificPopup.open({
				items: {src: '#coupon-use-comp'},
				type: 'inline',
				callbacks: {
					close: function(){
						location.reload();
					}
				},
				modal: true
			}, 0);
		} else {
			$.magnificPopup.open({
				items: {src: '#coupon-use-error'},
				type: 'inline',
				modal: true
			}, 0);
			if(data && data.result && data.result.error && data.result.error != ""){
				$('#coupon-use-error p.delete-errortext').html(data.result.error)
			}
			$('#coupon-use-error .w-btn').on('click', function(event){
				$.magnificPopup.close();
				_this.useCoupon(event);
			});
		}
	}).fail( function(data) {
		// console.log("cancel fail");
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#coupon-use-error'},
			type: 'inline',
			modal: true
		}, 0);
		$('#coupon-use-error .w-btn').on('click', function(event){
			$.magnificPopup.close();
			_this.useCoupon(event);
		});
	});
};

/**
 * クーポン店舗ごと使用完了
 * @method useShopCoupon
 * @param {event} event
 */
EPK.Member.prototype.useShopCoupon = function(event){

 	var coupon_used_csrf_token = $(event.target).data('coupon_used_csrf_token');
	var controller_name = $(event.target).data('controller_name');

	var url = '/coupon/used_shop/'+$(event.target).data('shop_id');
	var _this = this;
	$.ajax({
		url: url,
		type: 'POST',
		data: {"coupon_used_csrf_token": coupon_used_csrf_token, "controller_name": controller_name},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data && data.result && data.result.status && data.result.status == 1){
			$.magnificPopup.open({
				items: {src: '#modal-coupon-used'},
				type: 'inline',
				callbacks: {
					close: function(){
						// マイページクーポン画面へリダイレクト
						location.href = '/member/coupon';
					}
				},
				modal: true
			}, 0);
		} else {
			$.magnificPopup.open({
				items: {src: '#coupon-use-error'},
				type: 'inline',
				modal: true
			}, 0);
			if(data && data.result && data.result.error && data.result.error != ""){
				$('#coupon-use-error p.delete-errortext').html(data.result.error)
			}
			// $(event.target).data('shop_id')が取得できないので再試行不可=>ボタン非表示
			$('#coupon-use-error ul').hide();
			/*
			$('#coupon-use-error .w-btn').on('click', function(event){
				$.magnificPopup.close();
				_this.useShopCoupon(event);
			});
			*/
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#coupon-use-error'},
			type: 'inline',
			modal: true
		}, 0);
		$('#coupon-use-error ul').hide();
		/*
		$('#coupon-use-error .w-btn').on('click', function(event){
			$.magnificPopup.close();
			_this.useShopCoupon(event);
		});
		*/
	});
};

/**
 * ブランドクーポン使用完了
 * @method useBrandCoupon
 * @param {event} event
 */
EPK.Member.prototype.useBrandCoupon = function(event){

	var url = '/coupon/used_brand/'+$(event.target).data('id');
	var _this = this;
	$.ajax({
		url: url,
		type: 'POST',
		data: {},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data && data.result && data.result.status && data.result.status == 1){
			$.magnificPopup.open({
				items: {src: '#modal-coupon-used'},
				type: 'inline',
				callbacks: {
					close: function(){
						// マイページクーポン画面へリダイレクト
						location.href = '/member/coupon';
					}
				},
				modal: true
			}, 0);
		} else {
			$.magnificPopup.open({
				items: {src: '#coupon-use-error'},
				type: 'inline',
				modal: true
			}, 0);
			if(data && data.result && data.result.error && data.result.error != ""){
				$('#coupon-use-error p.delete-errortext').html(data.result.error)
			}
			// $(event.target).data('id')が取得できないので再試行不可=>ボタン非表示
			$('#coupon-use-error ul').hide();
			/*
			$('#coupon-use-error .w-btn').on('click', function(event){
				$.magnificPopup.close();
				_this.useBrandCoupon(event);
			});
			*/
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#coupon-use-error'},
			type: 'inline',
			modal: true
		}, 0);
		$('#coupon-use-error ul').hide();
		/*
		$('#coupon-use-error .w-btn').on('click', function(event){
			$.magnificPopup.close();
			_this.useBrandCoupon(event);
		});
		*/
	});
};

EPK.Member.prototype.coupon_init = function(){

	// 実施前に一旦イベントoffにする
	this.coupon_init_off();

	var _this = this;

	modalIdDeleteOpen = '#modal-coupon-delete';
	modalIdDeletePointOpen = '#modal-coupon-delete-point';
	modalIdDelete = '#modal-coupon-deleted';

	$('.coupon-del-btn').on('click', function(event){
		_this.showCouponDeleteOpen(event, modalIdDeleteOpen);
	});

	$('.coupon-del-btn-point').on('click', function(event){
		_this.showCouponDeleteOpen(event, modalIdDeletePointOpen);
	});

	$('#modal-coupon-delete .yes-btn a').on('click', function(event){
		_this.deleteCoupon(event, modalIdDelete);
	});

	$('#modal-coupon-delete-point .yes-btn a').on('click', function(event){
		_this.deleteCoupon(event, modalIdDelete);
	});

	$('.coupon-use-btn').on('click', function(event){
		_this.showCouponUseOpen(event);
	});

	$('#coupon-use-check .b-btn').on('click', function(event){
		_this.useCoupon(event);
	});
	// クーポンのチェックボックスイベント
	$('.shop-couponlist').find('input[type="checkbox"]').on('change', function (event) {
		var targetCheckbox = event.currentTarget;
		var key = targetCheckbox.className;
		var id = targetCheckbox.id;
		if (targetCheckbox.checked) {
			_this.checkCoupon(key, id);
			return;
		}
		_this.unCheckCoupon(key, id)
	});

	// history_back対応：check済みの要素＆固定フッター制御
	window.addEventListener('load',function(){
		_this.initCouponFadeUpFooter(_this)
	});

	// 固定フッター：選択を解除する
	$('#coupon-uncheck-all-btn').on('click', function(event){
		_this.unCheckCouponAll(event);
	});

	// 固定フッター：クーポンを選択して進む
	$('#coupon-confirm-btn').on('click', function(event){
		_this.showConfirmCouponUseOpen(event);
	});

	// 店舗クーポン：クーポンを利用する / 承諾して有効にする
	$('.use-btn a.use-shop-coupon').on('click', function(event){
		_this.confirmCouponUse(event);
	});

	// 店舗スタッフ用 確認ボタン（通常クーポン）
	$('#coupon-use-shop').on('click', function(event){
		_this.useShopCoupon(event);
	});

	// 店舗スタッフ用 確認ボタン（ブランドクーポン）
	$('#coupon-use-brand').on('click', function(event){
		_this.useBrandCoupon(event);
	});

	// クーポン詳細・利用条件ポップアップ
	var couponDetailAndUseConditionPopupSelector = '.modal-coupon-detail-btn'
		+ ', .modal-coupon-use-condition-btn'
		+ ', .modal-brand-coupon-detail-btn'
		+ ', .modal-brand-coupon-use-condition-btn'
		+ ', .modal-brand-coupon-detail-overall-price';
	$(couponDetailAndUseConditionPopupSelector).on('click', function() {
		var target = $(this).data('target');
		_this.openPopup(target);
	});

};

EPK.Member.prototype.coupon_init_off = function(){
	$('.coupon-del-btn').off('click');
	$('.coupon-del-btn-point').off('click');
	$('#modal-coupon-delete .yes-btn a').off('click');
	$('#modal-coupon-delete-point .yes-btn a').off('click');
	$('.coupon-use-btn').off('click');
	$('#coupon-use-check .b-btn').off('click');
	// クーポンのチェックボックスイベント
	$('.shop-couponlist').find('input[type="checkbox"]').off('change');
	$('#coupon-uncheck-all-btn').off('click');
	$('#coupon-confirm-btn').off('click');
	$('.use-btn a.use-shop-coupon').off('click');
	$('#coupon-use-shop').off('click');
	$('#coupon-use-brand').off('click');

	// クーポン詳細・利用条件ポップアップ
	var couponDetailAndUseConditionPopupSelector = '.modal-coupon-detail-btn'
		+ ', .modal-coupon-use-condition-btn'
		+ ', .modal-brand-coupon-detail-btn'
		+ ', .modal-brand-coupon-use-condition-btn'
		+ ', .modal-brand-coupon-detail-overall-price';
	$(couponDetailAndUseConditionPopupSelector).off('click');
};

/**
 * ブランドクーポン使用確認ポップアップ
 * @method showBrandCouponUseOpen
 * @param {event} event
 */
EPK.Member.prototype.showBrandCouponUseOpen = function(event){
	// クーポン使用APIURL
	this.usedCouponUrl = $(event.target).data("url");
	// クーポン使用表示URL
	this.viewCouponUrl = $(event.target).data("back_url");
	// バーコード有効時間
	this.couponExpireMinutes = $(event.target).data("expire_minutes");
	$.magnificPopup.open({
		items: {src: '#modal-brand-coupon-use-time-limit'},
		type: 'inline',
		modal: true
	}, 0);
	// 文言変更
	$('#modal-brand-coupon-use-time-limit').find('span.coupon-expire-minutes').text(this.couponExpireMinutes);
};

/**
 * ブランドクーポン使用
 * @method useCampaignCoupon
 * @param {event} event
 */
EPK.Member.prototype.useCampaignCoupon = function(event){

	var _this = this;
	$.ajax({
		url: this.usedCouponUrl,
		type: 'POST',
		data: {},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if (data && data.result && data.result.status && data.result.status == 1) {
			// クーポン使用表示画面へリダイレクト
			location.href = this.viewCouponUrl;
			return false;
		} else {
			$.magnificPopup.open({
				items: {src: '#coupon-use-error'},
				type: 'inline',
				callbacks: {
					close: function(){
						// マイページクーポン画面へリダイレクト
						location.href = '/member/coupon';
					}
				},
				modal: true
			}, 0);
			if(data && data.result && data.result.error && data.result.error != ""){
				$('#coupon-use-error .text').html(data.result.error);
			};
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#coupon-use-error'},
			type: 'inline',
			callbacks: {
				close: function(){
					// マイページクーポン画面へリダイレクト
					location.href = '/member/coupon';
				}
			},
			modal: true
		}, 0);
	});
};

/**
 * 既存ブランドクーポン使用完了
 * @method useCampaignCouponComp
 * @param {event} event
 */
EPK.Member.prototype.useCampaignCouponComp = function(event){

	var _this = this;
	this.couponId = $(event.target).data("id");
	this.usedCouponUrl = '/brand_coupon/used_comp/'+this.couponId;
	$.ajax({
		url: this.usedCouponUrl,
		type: 'POST',
		data: {},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data && data.result && data.result.status && data.result.status == 1){
			// クーポン使用完了モーダル表示
			$.magnificPopup.open({
				items: {src: '#modal-coupon-used'},
				type: 'inline',
				callbacks: {
					close: function(){
						// マイページクーポン画面へリダイレクト
						location.href = '/member/coupon';
					}
				},
				modal: true
			}, 0);
		} else {
			$.magnificPopup.open({
				items: {src: '#campaign-coupon-use-comp-error'},
				type: 'inline',
				modal: true
			}, 0);
			if(data && data.result && data.result.error && data.result.error != ""){
				$('#campaign-coupon-use-comp-error p').html(data.result.error)
			};
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#campaign-coupon-use-comp-error'},
			type: 'inline',
			modal: true
		}, 0);
	});
};

EPK.Member.prototype.changeBarcodeImg = function(event){
	// バーコード画像・ボタン文言を入れ替える
	var button_txt = $('#barcode-chenge-btn').attr('data-button_txt');
	var barcode_img = $('#barcode-chenge-btn').attr('data-barcode_img');
	var chenge_txt = $('#barcode-chenge-btn').attr('data-chenge_txt');
	var before_button_txt = $('#barcode-chenge-btn').text();
	var before_barcode_img = $('.special-box-code img').attr('src');
	var before_chenge_txt = $('.special-box-txt').length ? $('.special-box-txt').text() : '';

	$('#barcode-chenge-btn').text(button_txt);
	$('.special-box-code img').attr('src', barcode_img);
	if ($('.special-box-txt').length) {
		$('.special-box-txt').text(chenge_txt);
	}
	$('#barcode-chenge-btn').attr('data-button_txt', before_button_txt);
	$('#barcode-chenge-btn').attr('data-barcode_img', before_barcode_img);
	$('#barcode-chenge-btn').attr('data-chenge_txt', before_chenge_txt);
};

/**
 * クーポン選択用のフロート固定フッターの表示/非表示
 */
EPK.Member.prototype.showCouponFadeUpFooter = function () {
	if(this.fadeUpFooter) {
		$(this.fadeUpFooter).stop().animate({'bottom' : '0px'}, 300);
	}
}
EPK.Member.prototype.hideCouponFadeUpFooter = function () {
	if(this.fadeUpFooter) {
		$(this.fadeUpFooter).stop().animate({'bottom' : '-100px'}, 300);
	}
}
EPK.Member.prototype.initCouponFadeUpFooter = function (_this) {
	var checkedList = document.querySelectorAll('.shop-couponlist input[type="checkbox"]:checked');
	this.selectedCouponData = null;
	[].forEach.call(checkedList, function (checkbox) {
		var key = checkbox.className;
		var id = checkbox.id;
		if (checkbox.checked) {
			_this.checkCoupon(key, id);
		}
	});
}
/*
 * 消込画面表示前の確認モーダル表示(固定フッター)
 */
EPK.Member.prototype.showConfirmCouponUseOpen = function(event){

	//var objBtn = $('#coupon-confirm-btn');
	var countChecked = $('.shop-couponlist').find('input[type="checkbox"]:checked').length;

	var addQuery = '';
	var tmpUrl = '';
	var tmpBackUrl = '';
	var tmpViewType = '';
	var tmpExpireMinutes = '';
	var checkedList = document.querySelectorAll('.shop-couponlist input[type="checkbox"]:checked');
	[].forEach.call(checkedList, function (checkbox) {
		if(tmpUrl == '') {
			tmpUrl = $(checkbox).attr('target-url');
		}
		if(tmpBackUrl == '') {
			tmpBackUrl = $(checkbox).attr('target-back_url');
		}
		if(tmpViewType == '') {
			tmpViewType = $(checkbox).attr('target-view_type');
		}
		if(tmpExpireMinutes == '') {
			tmpExpireMinutes = $(checkbox).attr('target-expire_minutes');
		}
		addQuery += '&selected[]=' + $(checkbox).attr('target-id');
	});
	this.usedCouponUrl = tmpUrl + addQuery;
	this.viewCouponUrl = tmpBackUrl;
	this.couponViewType = tmpViewType;
	this.couponExpireMinutes = tmpExpireMinutes;

	var modalId = '#modal-coupon-use-time-no-limit';
	if(this.couponViewType != 0) {
		modalId = '#modal-coupon-use-time-limit';
	}
	$.magnificPopup.open({
		items: {src: modalId},
		type: 'inline',
		modal: true
	}, 0);
	// 文言変更
	$(modalId).find('span.coupon-count-checked').text(countChecked);
	if(this.couponViewType != 0) {
		$(modalId).find('span.coupon-expire-minutes').text(this.couponExpireMinutes);
	}
};


/**
 * クーポンのチェックボックス選択
 * @method checkCoupon
 * @param {string} key 選択したクーポンの店舗key(idとは異なる一意の情報)
 * @param {string} couponElmId 選択したクーポンの要素id
 */
EPK.Member.prototype.checkCoupon = function (key, couponElmId) {
	// 選択済みデータがなしの場合
	if (!this.selectedCouponData) {
		this.selectedCouponData = {};
		this.selectedCouponData[key] = [];
		// 店舗をまたいでクーポンを選択できないように制御
		var disableCheckboxList = document.querySelectorAll('.shop-couponlist input[type="checkbox"]:not(.' + key + ')');
		[].forEach.call(disableCheckboxList, function (checkbox) {
			checkbox.disabled = true;
		});

		// クーポン選択用のフロート固定フッターの表示
		this.showCouponFadeUpFooter();
	}
	if (!Object.hasOwnProperty.call(this.selectedCouponData, key)) {
		return;
	}
	// 選択クーポンを追加
	this.selectedCouponData[key].push(couponElmId);
}
/**
 * クーポンのチェックボックス解除
 * @method unCheckCoupon
 * @param {string} key 選択したクーポンの店舗key(idとは異なる一意の情報)
 * @param {string} couponElmId 選択したクーポンの要素id
 */
EPK.Member.prototype.unCheckCoupon = function (key, couponElmId) {
	if (!this.selectedCouponData || !Object.hasOwnProperty.call(this.selectedCouponData, key)) {
		return;
	}
	// チェック中のクーポンリストに該当のidがあるか確認
	var selectedIdx = this.selectedCouponData[key].indexOf(couponElmId);
	if (selectedIdx === -1) {
		return;
	}
	// チェック解除
	this.selectedCouponData[key].splice(selectedIdx, 1);
	// チェック済みクーポンがなくなった場合
	if (!this.selectedCouponData[key].length) {
		this.selectedCouponData = null;
		var disableCheckboxList = document.querySelectorAll('.shop-couponlist input[disabled][type="checkbox"]');
		[].forEach.call(disableCheckboxList, function (checkbox) {
			checkbox.disabled = false;
		});

		// クーポン選択用のフロート固定フッターの非表示
		this.hideCouponFadeUpFooter();
	}
}

/*
 * 固定フッター：選択を解除する
 */
EPK.Member.prototype.unCheckCouponAll = function (event) {
	this.selectedCouponData = null;
	var checkedList = document.querySelectorAll('.shop-couponlist input[type="checkbox"]:checked');
	[].forEach.call(checkedList, function (checkbox) {
		checkbox.checked = false;
	});
	var disableCheckboxList = document.querySelectorAll('.shop-couponlist input[disabled][type="checkbox"]');
	[].forEach.call(disableCheckboxList, function (checkbox) {
		checkbox.disabled = false;
	});

	// クーポン選択用のフロート固定フッターの非表示
	this.hideCouponFadeUpFooter();
}

/*
 * 獲得済みの利用可能クーポンのステータス更新
 */
EPK.Member.prototype.confirmCouponUse = function(event){

	var _this = this;
	$.ajax({
		url: this.usedCouponUrl,
		type: 'GET',
		data: {},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data && data.result && data.result.status && data.result.status == 1){
			location.href = this.viewCouponUrl;
			return false;
		} else {
			$.magnificPopup.open({
				items: {src: '#coupon-use-error'},
				type: 'inline',
				callbacks: {
					close: function(){
						location.reload();
					}
				},
				modal: true
			}, 0);
			if(data && data.result && data.result.error && data.result.error != ""){
				$('#coupon-use-error .text').html(data.result.error)
			};
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#coupon-use-error'},
			type: 'inline',
			modal: true
		}, 0);
	});
};


/**
 * ブランドクーポンの設定
 * @method initDisplayCoupon
 * @param {Array.Element} brandCouponEls クーポンエレメントリスト
 */
EPK.Member.prototype.initDisplayCoupon = function (brandCouponEls) {
	var len = brandCouponEls ? brandCouponEls.length : 0;
	if (!len) {
		return;
	}
	for (var couponIdx = 0; couponIdx < len; couponIdx++) {
		var couponElm = brandCouponEls[couponIdx];

		var countdownEl = couponElm.querySelector('.count_down');
		var couponImgDiv = couponElm.querySelector('.barcode div'); // 先頭の画像要素

		if (couponImgDiv) {
			// デフォルト表示する画像の一意のクラス名を取得
			var imageClassName = '';
			Array.prototype.forEach.call(couponImgDiv.classList, function(name) {
				if (name.indexOf('coupon_image') !== -1) {
					imageClassName = name;
					return true;
				}
			});
			// 初期画像の設定
			var targetLiBtn = couponElm.querySelector('.code-list-btn li[id="' + imageClassName + '"]');
			this.changeCouponImg(targetLiBtn);

			// 画像が1つしかない場合の対応
			if (!targetLiBtn) couponImgDiv.style.display = 'table';
		}

		if (!countdownEl) return;

		// クーポン分残り時間のカウントダウンタイマーの設定
		var nowDateStr = countdownEl.getAttribute("data-now");
		var expireDateTime = new Date(countdownEl.getAttribute("data-expire")).getTime();
		var nowDateTime = new Date(nowDateStr).getTime();
		// タイマー作成
		var timer = this.createCouponCountdownTimer(nowDateStr, expireDateTime - nowDateTime, countdownEl);
		// タイマー開始
		timer.startCountdown(500);
		this.couponCountdownTimerList.push(timer);
	}
	// PC版店舗クーポン表示時、高さを揃える
	EPK.utils.resizeElementHeight('.codelist');
};
/**
 * クーポン用のカウントダウンタイマー作成
 * @method createCouponCountdownTimer
 * @param {String} startTimeStr タイマー開始の日時文字列(Dateオブジェクトに変換できる文字列)
 * @param {Number} limitMillisecond 残り時間(ミリ秒)
 */
EPK.Member.prototype.createCouponCountdownTimer = function (startTimeStr, limitMillisecond, targetEl) {
	var param = {
		startTimeStr: startTimeStr || '',
		limitMillisecond: limitMillisecond || 0
	};
	var couponTimer = new CountdownTimer(param, targetEl);
	couponTimer.timeoutCountdown = this.timeoutCouponCountdown.bind(this);
	couponTimer.continueCountdown = this.updateCouponCountdownTime;
	return couponTimer;
};

/**
 * クーポンの残り時間表示
 * @method updateCouponCountdownTime
 * @param {Number} countDownMs 残り時間(ミリ秒)
 * @param {Element} elem 文字を表示するエレメント
 */
EPK.Member.prototype.updateCouponCountdownTime = function (countDownMs, elem) {
	if (!elem) {
		return;
	}
	var hhText = EPK.utils.formatMillisecond(countDownMs || 0, 'hh');
	var timeText = EPK.utils.formatMillisecond(countDownMs || 0, 'mm分ss秒');
	// 時間が00の場合は画面に表示しない
	timeText = (hhText !== '00' ? hhText + '時間' : '') + timeText;
	elem.innerHTML = timeText;
};
/**
 * クーポンの時間切れ時の処理
 * @method timeoutCouponCountdown
 * @param {Element} elem 文字を表示するエレメント
 */
EPK.Member.prototype.timeoutCouponCountdown = function (elem) {
	if (elem) {
		// 明示的に表示時間を00分00秒にする
		this.updateCouponCountdownTime(0, elem);

		// @TODO 暫定時間切れ表示処理
		var parentEl = elem.parentElement.parentElement.parentElement;
		if (!parentEl) return;
		// 画像を時間切れ表示にする
		parentEl.style.position = 'relative';
		var timeoutEl = parentEl.querySelector('.barcode-timeout');
		if (timeoutEl) timeoutEl.style.display = 'block';
		// ボタンをすべて不活性にする
		var btnList = parentEl.parentElement.querySelectorAll('.code-list-btn .is-active');
		[].forEach.call(btnList, function (btn) {
			btn.classList.remove('is-active');
		});
	}
};


/**
 * クーポンの画像切り替え
 * @method changeCouponImg
 * @param {element} targetBtnLi
 */
EPK.Member.prototype.changeCouponImg = function (targetBtnLi) {
	if(!targetBtnLi) return;

	var btnUl = targetBtnLi.parentElement;
	var parentEl = btnUl.parentElement;

	// ターゲットボタン以外のボタン・ボタンに紐づく画像を未選択状態表示に切り替え
	var btnList = btnUl.querySelectorAll(':not(.is-active)');
	[].forEach.call(btnList, function (btn) {
		btn.classList.add('is-active');
	});
	var imgDivs = parentEl.querySelectorAll('.barcode div:not(.' + targetBtnLi.id + ')');
	[].forEach.call(imgDivs, function (el) {
		el.style.display = 'none';
	});
	// ターゲットボタンとボタンに紐づく画像を選択状態表示に切り替え
	targetBtnLi.classList.remove('is-active');
	var img = parentEl.getElementsByClassName(targetBtnLi.id);
	if (img && img.length) img[0].style.display = 'table';
};




EPK.Member.prototype.campaign_coupon_init = function(){

	var _this = this;
	$('.usecoupon-btn .brand-coupon-confirm-btn').on('click', function(event){
		_this.showBrandCouponUseOpen(event);
	});

	// ブランドクーポン利用するボタン
	$('#modal-brand-coupon-use-time-limit .use-btn').on('click', function(event){
		_this.useCampaignCoupon(event);
	});
/* 各functionで定義
	$('#coupon-use-error .w-btn').on('click', function(event){
		$.magnificPopup.close();
		_this.useCampaignCoupon(event);
	})
*/
	$('#campaign-coupon-use-comp-btn').on('click', function(event){
		_this.useCampaignCouponComp(event);
	});

	// バーコードクーポン
	var brandCouponEls = document.getElementsByClassName('bcode-detail');
	// PC店舗バーコードクーポン
	var shopCouponEls = document.getElementsByClassName('codelist');
	// PC店舗通常クーポン
	var shopCouponListEls = document.getElementsByClassName('bcode-detail-list');
	if(brandCouponEls && brandCouponEls.length){
		// クーポンの初期表示
		_this.initDisplayCoupon(brandCouponEls);
	}else if(shopCouponEls && shopCouponEls.length){
		// クーポンの初期表示
		_this.initDisplayCoupon(shopCouponEls);
	}else if(shopCouponListEls && shopCouponListEls.length){
		// PC版店舗クーポン表示時、高さを揃える
		EPK.utils.resizeElementHeight('.bcode-detail-list');
	}
	// クーポンのイベント
	$('.code-list-btn').find('li').on('click', function(event) {
		var targetBtnLi = event.currentTarget;
		if(targetBtnLi.classList.contains('is-active')) {
			// 活性のボタンの場合
			_this.changeCouponImg(targetBtnLi);
		}
	});

};

EPK.Member.reload = function(){
	// リダイレクト
	location.reload();
};

EPK.Member.deleteBookmark = function (event) {
	//console.log(this.deleteBookmarkShopLink);
	var _this = this;
	var targetUrl = this.deleteBookmarkShopLink+"/bookmark";
	$.ajax({
		url: targetUrl,
		type: 'POST',
		data: {"op": "delete"},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		this.deleteBookmarkShopBox.remove(0);
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#modal-bookmark-delete-complete'},
			type: 'inline',
			modal: true
		}, 0);
	}).fail( function(data) {
		console.log("shop like fail");
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#modal-bookmark-delete-fail'},
			type: 'inline',
			modal: true
		}, 0);
	});
};

EPK.Member.prototype.initialize = function(){
	var _this = this;

	$("#shop-list").on("click",".btnModalCancel", function(event){
		_this.isDeliveryReserve = $(this).hasClass('delivery') ? true : false;
		_this.showCancelOpen(event);
		event.preventDefault();
	});
	$('#cancel-yes').on('click', function(event){
		$.magnificPopup.close();
		if (_this.isDeliveryReserve) {
			_this.showCancelReasonForDeliveryReserve(event);
		} else {
			_this.showCancelReason(event);
		}
	});
	$('#cancel-no, #receive-status-no').on('click', function(event){
		$.magnificPopup.close();
	});
	$('#cancel-resend-btn').on('click', function(event){
		_this.callReserveCancel(event);
	});
	$('#cancel-reason-btn').on('click', function(event){
		_this.sendCancelReason(event);
	});
	$('#cancel-reason-delivery-btn').on('click', function(event){
		_this.sendCancelReasonForDeliveryReserve(event);
	});
	$('#cancel-reason-resend-btn').on('click', function(event){
		_this.showCancelReason(event);
	});
	$('#cancel-reason-delivery-resend-btn').on('click', function(event){
		_this.showCancelReasonForDeliveryReserve(event);
	});
	$('#cancel-complete-close-btn').on('click', function(event){
		$.magnificPopup.close();
		location.reload();
	});
	// クーポン非同期追記読み込み
	$('.coupon-more-view').on('click', function(event){
		_this.showMoreShopCoupons(event);
	});
	$("#shop-list").on("click", ".btnModalReceive", function(event){
		_this.showReceiveConfirmOpen(event);
		event.preventDefault();
	});
	$('#receive-status-yes, #status-change-resend-btn').on('click', function(event){
		$.magnificPopup.close();
		_this.changeReserveStatus();
	});
	$('#cancel_message').on('keyup', function(){
		_this.ShowInputLength();
	});
	$('#cancel-execute-btn').on('click', function(event){
		_this.executeReserveCancelReason(event);
	});
	$('#complete-cancel-yes').on('click', function(event){
		$.magnificPopup.close();
		location.href = '/member/reserve';
	});
	$('#select-search-year').on('change', function(){
		var select_val = $(this).val();
		var search_url = '/member/reserve?target_year='+select_val;
		location.href = search_url;
	});

	this.coupon_init();
	this.campaign_coupon_init();
	this.torioki_init();
	if (EPK.MemberDisp.hasTmpStockCoupon) {
		this.webcm_coupon_init();
	};
	this.kmpoint_init();

	//お気に入り削除ボタンクリック
	$("#shop-list").on('click', '.btn-deleteBookmarkConfirm',function(event) {
		EPK.Member.showConfirmDeleteBookmark(event);
	});
	$('#btn-deleteBookmark').on('click', function(event) {
		EPK.Member.deleteBookmark(event);
	});
	$('#btn-deleteBookmarkComplete').on('click', function(event) {
		if($(".shop-box").size() == 0) {
			location.reload();
		}
	});

	//「もっと見る」クリック
	$("#section-loadMore").on('click', '.btn-loadMore',function(event) {
		//続きをrendering
		EPK.Member.loadMoreList(event, '3');
	});
	$("#section-loadMore2").on('click', '.btn-loadMore2',function(event) {
		EPK.Member.loadMoreList(event, '2');
	});

	$("#campaign-entry-list").on('click', '.btn-loadMore',function(event) {
		EPK.Member.loadMoreList(event, '');
	});

	//住所を自動入力クリック
	$('#search_address').on('click', function(event){
		 EPK.Member.getAddressFromZipCode();
	});

	//満足不満足ボタンクリック
	//モーダル画面での投稿
	$('.satisfaction-btn, .modal-satisfaction-btn').on('click', '', function(event){
		EPK.Member.updateSatisfactionInfo(event);
	});
	$('.submit-vote-satisfaction-text, .submit-vote-satisfaction-review').on('click', function(event){
		EPK.Member.updateSatisfactionInfo(event);
	});
	//モーダルの閉じるボタンクリック
	$('.close-vote-satisfaction').on('click', function(event){
		EPK.Member.clearSatisfactionParam();
	});

	//PC版左メニュー クレジットカード情報クリック
	$('#nav-menu-credit-my-page').on('click', function(event){
		EPK.Member.openEparkCreditPage();
	});

	//メールマガジン設定クリック
	$('#nav-menu-mail-magazine-my-page').on('click', function(event){
		EPK.common.openEparkMemberEditPage();
	});

	// 駐車場入口写真リンククリック
	$('.parking-photo').on('click', function(event){
		EPK.Member.showParkingPhotoOpen(event);
	});

	// 駐車場到着をお知らせするボタンクリック
	$('.send-parking-reach-btn').on('click', function(event){
		EPK.Member.showSendParkingReachOpen(event);
	});

	// 到着連絡するボタンクリック
	$('#send-parking-reach-popup .y-btn, #parking-reach-fail-popup .y-btn').on('click', function(event){
		if (!$(this).hasClass('disabled')) {
			EPK.Member.sendParkingReach();
		}
	});

	// #11987 予約ステータス、支払い履歴の追加（薬局・病院）受け取り完了をクリック
	var receiveBtn = document.getElementsByClassName('remote_shoho_status_complete_btn');
	if (receiveBtn.length > 0) {
		[].forEach.call(receiveBtn,function (elem) {
			elem.addEventListener('click',EPK.Member.receiveCompleate);
		})
	}

	// EPARK本部側のクーポン位置を変更
	EPK.Member.insertEparkCoupon();

// #11987 予約ステータス、支払い履歴の追加（薬局・病院）
	EPK.Member.tabsSwiching();
// #11987 予約ステータス、支払い履歴の追加（薬局・病院）

	$('.remote_outpatient_cancel_btn').each(function(i,e) {
		$(e).off('click.cancelbtn').on('click.cancelbtn', function(event) {
			EPK.Member.sendCancelForRemoteOutpatient(event);
		});
	});
};

/**
 * PC版EPARKクレジットカード情報を開く
 */
var creditPageFormInit = function() {
    var $form = $('#creditPageForm');
    if ($form.length > 0) {
        $form.remove();
    }
};
EPK.Member.openEparkCreditPage = function(){
	creditPageFormInit();

	var current_url = location.href;
	var ajax_url = '/api/header/getCreditPage';
	$.ajax({
		url: ajax_url,
		type: 'GET',
		dataType: 'JSON',
		data: {current_url: current_url},
	})
	.done(function(formData) {
		var $body = $('body');
		var $form = $('<form>', {
				id: 'creditPageForm',
				method: 'post',
				action: formData.action,
				target: '_blank'
		});
		$body.append($form);
		$.each(formData.data, function(key, value) {
			$('<input>', {
				type: 'hidden',
				name: key,
				value: value
			}).appendTo($form);
		});
		$form.submit();
	})
	.fail(function() {
		console.log("error");
	});
};

/**
 * 満足不満足度情報の更新
 */
EPK.Member.updateSatisfactionInfo = function(event){
	var fataiErrorMessage = '満足不満足アンケート回答時にエラーが発生しました。<br>お手数ですが画面を読み込み直してから再度回答してください。';

	var updateCount = $('#update_count_satisfaction_rating').val();
	updateCount = parseInt(updateCount);

	var satisfactionRating = 0;
	var newAppointId = 0;
	var voteReviewFlag = 0;
	var shopId = 0;
	var ratingQuestion = 0;
	var ratingComment = '';
	if(updateCount == 1){
		satisfactionRating = $(event.target).closest('.satisfaction-btn, .modal-satisfaction-btn').data('satisfaction_rating');
		newAppointId = $(event.target).closest('.review-buttons').data('new_appoint_id');
		shopId = $(event.target).closest('.review-buttons').data('shop_id');
	}else
	if(updateCount == 2){
		satisfactionRating = $('#satisfaction_rating_first').val();
		newAppointId = $('#appoint_id_satisfaction_rating').val();
		voteReviewFlag = $(event.target).closest('.submit-vote-satisfaction-review').data('vote_review_flag');
		shopId = $('#shop_id_satisfaction_rating').val();
		ratingQuestion = $('input[name=rating_question]:checked').val();
		ratingComment = $.trim($('#rating_comment_' + satisfactionRating).val());
	}

	var errorMessageDispAreaName = '#error-message-area-satisfaction-rating-' + newAppointId;
	if(updateCount == 2){
		errorMessageDispAreaName = '#error-message-area-satisfaction-rating-comment';
	}

	if(updateCount == 2){
		$('#rating_comment_' + satisfactionRating).removeClass('err');
	}

	if(updateCount < 1 || updateCount > 2){
		$(errorMessageDispAreaName).html(fataiErrorMessage);
		return false;
	}

	if(satisfactionRating < 1 || satisfactionRating > 3){
		$(errorMessageDispAreaName).html(fataiErrorMessage);
		return false;
	}
	if(!$.isNumeric(satisfactionRating)){
		$(errorMessageDispAreaName).html(fataiErrorMessage);
		return false;
	}

	if(ratingComment.length > 1000){
		$('#error-message-area-satisfaction-rating-comment').html('1000文字以下で入力してください。');
		$('#rating_comment_' + satisfactionRating).addClass('err');
		return false;
	}

	if(newAppointId.length < 1 || newAppointId < 1){
		$(errorMessageDispAreaName).html(fataiErrorMessage);
		return false;
	}

	$.ajax({
		type: 'POST',
		url: '/member/update_satisfaction.json',
		data: {
				'satisfaction_rating': satisfactionRating,
				'appoint_id': newAppointId,
				'update_count': updateCount,
				'rating_comment': ratingComment,
				'vote_review_flag': voteReviewFlag,
				'shop_id': shopId,
				'rating_question': ratingQuestion
			},
		cache: false,
		dataType : 'json',
		timeout: 80000
	}).done(function(data){
		if(data['result'] == 'success'){
			//初回更新時
			if(updateCount == 1){
				var targetModalIdList = {
					1 : 'vote-dissatisfaction',
					2 : 'vote-both',
					3 : 'vote-satisfaction'
				};

				var targetModalName = targetModalIdList[satisfactionRating];

				//選択ボタンを削除
				$('#satisfaction_answer_area_' + newAppointId).remove(0);

				//更新回数のインクリメント → 2回まで許可
				updateCount = updateCount + 1;
				if(updateCount > 2){
					updateCount = 2;
				}
				$('#update_count_satisfaction_rating').val(updateCount);

				$('#satisfaction_rating_first').val(satisfactionRating);
				$('#appoint_id_satisfaction_rating').val(newAppointId);
				$('#shop_id_satisfaction_rating').val(shopId);

				//次のモーダルを起動
				$.magnificPopup.open({
					items: {src: '#' + targetModalName},
					type: 'inline',
					modal: true
				}, 0);

			//2回目更新時
			}else{
				$.magnificPopup.close();
				EPK.Member.clearSatisfactionParam();

				//口コミ投稿を行う場合はリダイレクト
				if(voteReviewFlag){
					location.href = '/review/pharmacy?pharmacy_id=' + shopId + '&satisfaction_review=1';
				}
			}
		}else
		if(data['result'] == 'nothing'){
			$.magnificPopup.close();
			EPK.Member.clearSatisfactionParam();
		}else{
			$(errorMessageDispAreaName).html('満足不満足アンケートの更新に失敗しました。');
			console.log('updateSatisfaction is failed -- ' + data['result']);
			return false;
		}
	}).fail(function(data){
		$(errorMessageDispAreaName).html('満足不満足アンケートの更新に失敗しました。');
		console.log('updateSatisfaction is failed');
		return false;
	});
};

EPK.Member.clearSatisfactionParam = function(){
	$('#update_count_satisfaction_rating').val('1');
	$('#satisfaction_rating_first').val('');
	$('#appoint_id_satisfaction_rating').val('');
	$('#shop_id_satisfaction_rating').val('');
};

/**
 * 予約ボタン表示非同期処理
 */
EPK.Member.setReserveButton = function(){
	if($.isEmptyObject(EPK.MemberDisp.targetShopListSetReserveButton)){
		return false;
	}

	EPK.common = EPK.common || {};
	EPK.common.ShohoData = null;
	var url = "/api/shoho/check.json";

	//店舗IDのみ抽出
	this.shopIdList = Object.keys(EPK.MemberDisp.targetShopListSetReserveButton);
	//console.log(this.shopIdList);

	$.ajax({
		type: 'get',
		url: url,
		data: {"shop_id": this.shopIdList},
		cache: false,
		timeout: 60000
	}).done(function(data){
		EPK.common.ShohoData = data;
		//console.log(data);

		$('.loading_reservation_status').each(function(index, elem){
			var $elem = $(elem);

			var shop_id = $elem.attr('data-shop_id');
			var hospital_id = $elem.attr('data-hospital_department_id');

			if (shop_id) {
				var shop_name = $elem.attr('data-shop_name');
				var $template = $($elem.attr('data-template'));
				var class_name = $elem.attr('data-class_name');
				var label = $elem.attr('data-label') || "";
				var item_status = $elem.attr('data-item_status');
				var col_num = $elem.attr('data-col_num');
				//console.log(shop_id + ' ' + shop_name);

				var shopData = EPK.MemberDisp.targetShopListSetReserveButton[shop_id];
				//console.log(shopData);

				var params = {
					shop_id: shop_id,
					shohoStatusData: data[shop_id],
					shopData: shopData,
					className: class_name,
					label: label,
					itemStatus: item_status,
					colNum: col_num,
				};

				var html = $template.render(params);
				$elem.replaceWith(function() {
					return html;
				});
			}
		});

		//オンライン診療　もう一度予約するボタン
		EPK.Member.renderRemoteColutionBtn();

		// 外来診療　もう一度予約するボタン
		EPK.Member.renderRemoteOutpatientBtn();

		EPK.Delivery.Popup.initialize();

	}).fail(function(){
		console.log('setReserveButton Processing failed');
		console.log(this.shopIdList);
	});
};

/**
 * 郵便番号から住所を検索
 */
EPK.Member.getAddressFromZipCode = function(event){
	var zip_code = $('#zip-code').val();
	zip_code = zip_code.trim();

	if(EPK.MemberDisp.isMobile){
		$('#input-zip-code').removeClass('err');
		$('#input-zip-code').addClass('send');
	}else{
		$('#input-zip-code').removeClass('error');
	}
	$('#error-message-area-zip-code').remove();

	if(!$.isNumeric(zip_code)){
		EPK.Member.dispErrorMessageForGetAddressFromZipCode('・半角数値で入力してください。');
		return false;
	}

	if(zip_code.length != 7){
		EPK.Member.dispErrorMessageForGetAddressFromZipCode('・7桁で入力してください。');
		return false;
	}

	$.ajax({
		type: 'get',
		url: '/member/get_address_from_zip_code.json',
		data: {'zip_code': zip_code},
		cache: false,
	}).done(function(data){
		if(data['result'] == 'success'){
			if($.isEmptyObject(data['zipcode_data'])){
				EPK.Member.dispErrorMessageForGetAddressFromZipCode('・該当する市区町村がありません。');
				return false;
			}else{
				var zip_code_data = data['zipcode_data'];
				var address_data;

				if(zip_code_data['epark_prefecture_id'] > 0){
					$('#prefecture').val(zip_code_data['epark_prefecture_id']);
				}else{
					$('#prefecture').val(99);
				}

				if(zip_code_data['city_name'].trim().length > 0){
					address_data = zip_code_data['city_name'];
				}
				if(zip_code_data['detail_name'].trim().length > 0){
					address_data += zip_code_data['detail_name'];
				}

				$('#address').val(address_data);

				return true;
			}
		}else
		if(data['result'] == 'ng_num'){
			EPK.Member.dispErrorMessageForGetAddressFromZipCode('・半角数値で入力してください。');
			return false;
		}else
		if(data['result'] == 'ng_length'){
			EPK.Member.dispErrorMessageForGetAddressFromZipCode('・7桁で入力してください。');
			return false;
		}
	}).fail(function(data){
	});

};
EPK.Member.dispErrorMessageForGetAddressFromZipCode = function(message){
	if(EPK.MemberDisp.isMobile){
		$('#input-zip-code').addClass('err');
	}else{
		$('#input-zip-code').addClass('error');
	}
	$('#input-zip-code').children('.error-message').remove();
	$('#input-zip-code').after('<div id="error-message-area-zip-code" class="error-message"></div>');
	$('#error-message-area-zip-code').text(message);
};

/**
 * 満足不満足回答ボタン表示非同期処理
 */
EPK.Member.setSatisfactionAnswerArea = function(){
	if ($.isEmptyObject(EPK.MemberDisp.targetShopListSetSatisfactionAnswerArea)) {
		return false;
	}

	EPK.common = EPK.common || {};
	var url = "/member/get_satisfaction_answer_area.json";
	//console.log(EPK.MemberDisp.targetShopListSetSatisfactionAnswerArea);

	$.ajax({
		type: 'get',
		url: url,
		data: {'target_shop_list': EPK.MemberDisp.targetShopListSetSatisfactionAnswerArea},
		cache: false,
		timeout: 60000
	}).done(function(data){
		//console.log(data);
		var newAppointListEparkApi = data['new_appoint_list_epark_api'];
		//console.log(newAppointListEparkApi);

		$('.loading_satisfaction_answer_area').each(function(index, elem){
			var $elem = $(elem);

			var shop_id = $elem.attr('data-shop_id');
			var $template = $($elem.attr('data-template'));
			//console.log(shop_id);

			var shopData = EPK.MemberDisp.targetShopListSetSatisfactionAnswerArea[shop_id];
			//console.log(shopData);

			var newAppointDataEparkApi = {};
			if(!$.isEmptyObject(newAppointListEparkApi[shop_id])){
				newAppointDataEparkApi = newAppointListEparkApi[shop_id];
			}
			//console.log(newAppointDataEparkApi);

			var params = {
				newAppointDataEparkApi: newAppointDataEparkApi,
				shopData: shopData,
				Env: EPK.MemberDisp.Env
			};
			//console.log(params);

			var html = $template.render(params);
			$elem.replaceWith(function() {
				return html;
			});
		});
		// 満足不満足ボタンクリック
		$('.satisfaction-btn, .modal-satisfaction-btn').off('click');
		$('.satisfaction-btn, .modal-satisfaction-btn').on('click', function(event){
			EPK.Member.updateSatisfactionInfo(event);
		});
	}).fail(function(){
		console.log('setSatisfactionAnswerArea Processing failed');
		console.log(EPK.MemberDisp.targetShopListSetSatisfactionAnswerArea);
	});
};

/**
 * 満足不満足回答ボタン表示非同期処理（SP版）
 */
EPK.Member.setSatisfactionAnswerAreaForSP = function(){
	if ($.isEmptyObject(EPK.MemberDisp.targetReserveListSetSatisfactionAnswerArea)) {
		return false;
	}

	var url = "/member/get_satisfaction_answer_area.json";

	$.ajax({
		type: 'get',
		url: url,
		data: {'target_reserve_id_list': EPK.MemberDisp.targetReserveListSetSatisfactionAnswerArea},
		cache: false,
		timeout: 60000
	})
	.done(function(data) {
		var newAppointListEparkApi = data['new_appoint_list_epark_api'];

		$('.loading_satisfaction_answer_area').each(function(index, elem) {
			var $elem = $(elem);
			var reserve_id = $elem.attr('data-reserve_id');
			var shop_id = $elem.attr('data-shop_id');
			var $template = $($elem.attr('data-template'));

			var newAppointDataEparkApi = {};

			if (!$.isEmptyObject(newAppointListEparkApi[reserve_id])) {
				newAppointDataEparkApi = newAppointListEparkApi[reserve_id];
			}

			var shopData = EPK.MemberDisp.targetShopListSetReserveButton[shop_id];

			var params = {
				newAppointDataEparkApi: newAppointDataEparkApi,
				reserveId: reserve_id,
				shopData: shopData,
				Env: EPK.MemberDisp.Env
			};

			var html = $template.render(params);
			$elem.replaceWith(function() {
				return html;
			});
		});

		// 満足不満足ボタンクリック
		$('.satisfaction-btn, .modal-satisfaction-btn').off('click');
		$('.satisfaction-btn, .modal-satisfaction-btn').on('click', function(event) {
			EPK.Member.updateSatisfactionInfo(event);
		});
	})
	.fail(function() {
		console.log('setSatisfactionAnswerArea Processing failed');
		console.log(EPK.MemberDisp.targetReserveListSetSatisfactionAnswerArea);
	});
};

/**
 * 満足不満足コメント制御
 */
EPK.Member.countRatingComment = function(){
	if($(".text_satisfaction").val().length == 0){
		$(".satis input").prop("disabled", true);
		$(".disatis input").prop("disabled", true);
	}

	$('.disatis input').prop('disabled', 'disabled');
	$('.dissatisfaction-radio input[type="radio"]').click(function() {
		if($(this).prop('checked') == false){
			$('.disatis input').attr('disabled', 'disabled');
		}else{
			$('.disatis input').removeAttr('disabled');
		}
	});

	$(".text_satisfaction").on("keydown keyup keypress change", function() {
		var thisValueLength = $(this).val().replace(/\s+/g, '').length;

		if(thisValueLength == 0){
			$(".satis input").prop("disabled", true);
			$(".disatis input").prop("disabled", true);
		}else{
			$(".satis input").prop("disabled", false);
			$(".disatis input").prop("disabled", false);
		}

		$("#rating_comment_count").text(thisValueLength);
		if(thisValueLength > 1000){
			$('#rating_comment_count').addClass('rating_comment_over');
			$('#rating_comment_1').addClass('err');
			$('#rating_comment_3').addClass('err');
		}else{
			$('#rating_comment_count').removeClass('rating_comment_over');
			$('#rating_comment_1').removeClass('err');
			$('#rating_comment_3').removeClass('err');
		}
	});
};

EPK.Member.getReservedInfo = function (select_year) {
	var _this = this;
	var targetUrl = "/member/getReservedInfo";

	$.ajax({
		type: 'POST',
		url: targetUrl,
		cache: false,
		dataType : "json",
		context: _this,
		data: {
			"target_year": select_year,
		}
	}).done(function(data) {
		/* 利用期間セレクトボックスの要素を生成 */
		$.each(data['result']['year'], function(index, val){
			if(val == select_year) {
				$('#select-search-year').append($('<option value="'+val+'" selected>'+val+'</option>'));
			} else {
				$('#select-search-year').append($('<option value="'+val+'">'+val+'</option>'));
			}
		});

		/** 総ページ数を算出 */
		var total_reserved = data['result']['total_cnt'];
		var total_pages = Math.ceil(total_reserved / 20);

		var disp_now_page = 1;
		/** 「前の20件」「次の20件」ボタンの表示切替 */
		var disp_now_page = $('.page-count').text();
		var dispBtnList = EPK.Member.switchPrevAndNextBtn(disp_now_page, total_pages);
		if(dispBtnList['disp_prev']) {
			$('.page-rev').css('display', 'block');
		}
		if(dispBtnList['disp_next']) {
			$('.page-fwd').css('display', 'block');
		}

		/** 総ページ数の設定 */
		$('.page-count').append('/'+total_pages+'ページ');

		// 「前の20件」ボタンのパラメータ追加
		var prev_url = $('#prev-btn').attr('href');
		prev_url = prev_url+"&total_reserved_cnt="+total_reserved+"&disp_now_page="+disp_now_page;
		$('#prev-btn').attr('href', prev_url);

		// 「次の20件」ボタンのパラメータ追加
		var next_url = $('#next-btn').attr('href');
		next_url = next_url+"&total_reserved_cnt="+total_reserved+"&disp_now_page="+disp_now_page;
		$('#next-btn').attr('href', next_url);

	}).fail( function(data, textStatus) {

	});
};

EPK.Member.switchPrevAndNextBtn = function (dispNowPage, dispTotalPage) {
	var disp_btn = [];
	disp_btn['disp_prev'] = false;
	disp_btn['disp_next'] = false;

	if(!dispNowPage || !dispTotalPage) {
		return disp_btn;
	}
	if(dispTotalPage >= 2) {
		if(dispNowPage > 1) {
			disp_btn['disp_prev'] = true;
		}
		if (dispNowPage != dispTotalPage) {
			disp_btn['disp_next'] = true;
		}
	}
	return disp_btn;
};

$(function(){
    var obj = new EPK.Member();
	obj.initialize();
	obj.bindShowPassword();

	//予約ボタン表示非同期処理
	if(EPK.MemberDisp.isSetReserveButton){
		EPK.Member.setReserveButton();
	} else {
		if(EPK.MemberDisp.isSetHospitalReserveButton){
			EPK.Member.renderRemoteColutionBtn();
		}
		if(EPK.MemberDisp.isSetHospitalOutpatientReserveButton){
			EPK.Member.renderRemoteOutpatientBtn();
		}
	}

	//満足不満足
	if(EPK.MemberDisp.isSetSatisfactionAnswerArea){
		//回答ボタン非同期処理
		if (EPK.MemberDisp.isMobile) {
			EPK.Member.setSatisfactionAnswerAreaForSP();
		} else {
			EPK.Member.setSatisfactionAnswerArea();
		}

		//コメント制御
		EPK.Member.countRatingComment();
	}

	if(EPK.common.isLogin) {
		//過去の予約情報非同期処理
		var select_year = $("#target_search_year").val();
		EPK.Member.getReservedInfo(select_year);
	}

	//スペシャルクーポン制御
	$(window).bind("focus",function(){
		EPK.Member.isIntervalObserved = false;

		// タイマーが作動している場合
		if ($('#count_down').length && obj.timeid) {

			// clearInterval(this.timeid);
			this.timeid = null;

			// 再読み込み
			setTimeout(function() {

				// フォーカス時にinterval処理が停止しているようであればリロード
				if(EPK.Member.isIntervalObserved === false)
				{
					// タイマー解除
					clearInterval(obj.timeid);

					// リダイレクト
					EPK.Member.reload();
				}
			}, 500);
		}
	});

	if (EPK.MemberDisp.reserveNowCheckinList && EPK.MemberDisp.isMobile) {
		// 位置情報確認・チェックインAPI実行
		EPK.Member.checkin(false);
	}

	// brand_couponスライダー初期化
	var brandCouponSlider = document.getElementById('brand-coupon-slider');
	if(brandCouponSlider) {
		var brandCouponSlides = $(brandCouponSlider).find(".swiper-slide");
		if(brandCouponSlides.length > 1) {
			// 要素のリサイズ
			EPK.utils.resizeElementHeight('#brand-coupon-slider .coupon-slider-box');
			// SP
			if(EPK.common.isSP) {
				$(brandCouponSlider).swiper({
					centeredSlides: false,
					slidesPerView: 'auto',
					loop: false,
					// 全てのスライドの最後に追加で余白を設ける
					slidesOffsetAfter: 10,
				});
			} else if(brandCouponSlides.length > 2) {
				// PCはクーポン3枚以上でスライダー表示にする
				$(brandCouponSlider).swiper({
					centeredSlides: false,
					slidesPerView: 'auto',
					loop: false,
					nextButton: '.swiper-button-next',
					prevButton: '.swiper-button-prev',
					pagination: '.swiper-pagination',
					// 全てのスライドの最後に追加で余白を設ける
					slidesOffsetAfter: 19,
				});
			}
		}
	}
});


EPK.Member.prototype.torioki_init = function(){

	var _this = this;
	$('.torioki_cancel_btn').on('click', function(event){
		_this.showToriokiCancelOpen(event);
	});

	$('#torioki_cancel_comp_btn').on('click', function(event){
		if(!$('#torioki_cancel_comp_btn').hasClass('unclickable')) {
			$('#torioki_cancel_comp_btn').addClass('unclickable');
			_this.toriokiCancelComp(event);
		}
	});

	// #12232 del
	//$('.torioki_receive_btn').on('click', function(event){
	//	_this.showToriokiReceiveOpen(event);
	//});

	// #12232 del
	//$('#torioki_receive_comp_btn').on('click', function(event){
	//	if(!$('#torioki_receive_comp_btn').hasClass('unclickable')) {
	//		$('#torioki_receive_comp_btn').addClass('unclickable');
	//		_this.toriokiReceiveComp(event);
	//	}
	//});

	$('#more_order_in').children('p').children('a').on('click', function(event){
		_this.showMoreOrderIn(event);
	});

	$('#more_order_out').children('p').children('a').on('click', function(event){
		_this.showMoreOrderOut(event);
	});
};

// 注文中の商品をもっと見る
EPK.Member.prototype.showMoreOrderIn = function(event){

	var _this = this;
	var objSection = $('#order_in');
	var objLi = $('#more_order_in');
	var objEvent = objLi.children('p').children('a');

	var page = objEvent.attr('target-page');
	var url = '/member/torioki/order_in';

	objLi.remove();

	$.ajax({
		type: 'GET',
		url: url,
		cache: false,
		dataType : "html",
		data: {
			p: page
		}
	}).done(
		function(data) {
			if (data.length > 0) {
				objSection.append(data);

				if(EPK.MemberDisp.isSetReserveButton){
					setTimeout(function() {
						EPK.Member.setReserveButton();
					}, 1000);
				} else {
					if(EPK.MemberDisp.isSetHospitalReserveButton){
						EPK.Member.renderRemoteColutionBtn();
					}
					if(EPK.MemberDisp.isSetHospitalOutpatientReserveButton){
						EPK.Member.renderRemoteOutpatientBtn();
					}
				}

				_this.torioki_init();
			} else {
			}
		}
	).fail(
		function(data) {
		}
	);
};

// 購入履歴をもっと見る
EPK.Member.prototype.showMoreOrderOut = function(event){

	var _this = this;
	var objSection = $('#order_out');
	var objLi = $('#more_order_out');
	var objEvent = objLi.children('p').children('a');

	var page = objEvent.attr('target-page');
	var url = '/member/torioki/order_out';

	objLi.remove();

	$.ajax({
		type: 'GET',
		url: url,
		cache: false,
		dataType : "html",
		data: {
			p: page
		}
	}).done(
		function(data) {
			if (data.length > 0) {
				objSection.append(data);
				$('#more_order_out').children('p').children('a').on('click', function(event){
					_this.showMoreOrderOut(event);
				});
			} else {
			}
		}
	).fail(
		function(data) {
		}
	);
};


// 取り置きキャンセル
EPK.Member.prototype.showToriokiCancelOpen = function(event){

	var _this = this;
	var buy_no = $(event.target).data("buy_no");

	if(buy_no == ''){
		$('#torioki-error .txt-comp').html('エラーが発生しました。<br>画面を再読込してもう一度実行してください。');
		$.magnificPopup.open({
			items: {src: '#torioki-error'},
			type: 'inline',
			modal: true
		}, 0);
		return false;
	}

	var url = '/member/torioki/cancel/'+buy_no;

	$.ajax({
		url: url,
		type: 'GET',
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data.status == 1){
			$.magnificPopup.open({
				items: {src: '#torioki-cancel'},
				type: 'inline',
				modal: true
			}, 0);
			// テキスト等更新
			$('.number-box').text('受付番号：' + data.torioki.accept_no);
			$('#torioki_cancel_comp_btn').attr('data-buy_no', data.torioki.buy_no);
			$('#torioki_cancel_comp_btn').removeClass(function(index, className) {
				return (className.match(/\bGA_mypage_shoppinghistory_cancelpopup_\S+/g) || []).join(' ');
			});
			$('#torioki_cancel_comp_btn').addClass('GA_mypage_shoppinghistory_cancelpopup_' + data.torioki.accept_no + '_cancel_btn');
		} else {
			$.magnificPopup.open({
				items: {src: '#torioki-error'},
				type: 'inline',
				modal: true
			}, 0);
			if(data.error != ""){
				$('#torioki-error .txt-comp').text(data.error)
			};
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		console.log(data);
		$.magnificPopup.open({
			items: {src: '#torioki-error'},
			type: 'inline',
			modal: true
		}, 0);
	});
};


// 取り置きキャンセル完了
EPK.Member.prototype.toriokiCancelComp = function(event){

	var _this = this;
	var buy_no = $('#torioki_cancel_comp_btn').attr("data-buy_no");
	var url = '/member/torioki/exec_cancel/'+buy_no;

	var send_fax_cb = 0;
	if($('#send-fax-cb').prop('checked')){
		send_fax_cb = 1;
	}

	$.ajax({
		url: url,
		type: 'GET',
		cache: false,
		data: {'send_fax_cb': send_fax_cb},
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data.status == 1){
			$.magnificPopup.open({
				items: {src: '#torioki-cancel-comp'},
				type: 'inline',
				callbacks: {
					close: function(){
						location.reload();
					}
				},
				modal: true
			}, 0);
		} else {
			$.magnificPopup.open({
				items: {src: '#torioki-error'},
				type: 'inline',
				modal: true
			}, 0);
			if(data.error != ""){
				$('#torioki-error .txt-comp').text(data.error)
			};
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		console.log(data);
		$.magnificPopup.open({
			items: {src: '#torioki-error'},
			type: 'inline',
			modal: true
		}, 0);
	});
};


// 取り置き受け渡し
// #12232 del
/*
EPK.Member.prototype.showToriokiReceiveOpen = function(event){

	var _this = this;
	var buy_no = $(event.currentTarget).data("buy_no");

	if(buy_no == ''){
		$('#torioki-error .txt-comp').html('エラーが発生しました。<br>画面を再読込してもう一度実行してください。');
		$.magnificPopup.open({
			items: {src: '#torioki-error'},
			type: 'inline',
			modal: true
		}, 0);
		return false;
	}

	var url = '/member/torioki/receive/'+buy_no;

	$.ajax({
		url: url,
		type: 'GET',
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data.status == 1){
			$.magnificPopup.open({
				items: {src: '#torioki-receive'},
				type: 'inline',
				modal: true
			}, 0);
			// テキスト等更新
			$('.number-box').text('受付番号：' + data.torioki.accept_no);
			$('#torioki_receive_comp_btn').attr('data-buy_no', data.torioki.buy_no);
			$('#torioki_receive_comp_btn').removeClass(function(index, className) {
				return (className.match(/\bGA_mypage_shoppinghistory_receivepopup_receive_\S+/g) || []).join(' ');
			});
			$('#torioki_receive_comp_btn').addClass('GA_mypage_shoppinghistory_receivepopup_receive_' + data.torioki.accept_no + '_btn');
		} else {
			$.magnificPopup.open({
				items: {src: '#torioki-error'},
				type: 'inline',
				modal: true
			}, 0);
			if(data.error != ""){
				$('#torioki-error .txt-comp').text(data.error)
			};
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		console.log(data);
		$.magnificPopup.open({
			items: {src: '#torioki-error'},
			type: 'inline',
			modal: true
		}, 0);
	});
};
*/

// 取り置き受け渡し完了
// #12232 del
/*
EPK.Member.prototype.toriokiReceiveComp = function(event){

	var _this = this;
	var buy_no = $('#torioki_receive_comp_btn').attr("data-buy_no");
	var url = '/member/torioki/exec_receive/'+buy_no;

	$.ajax({
		url: url,
		type: 'GET',
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data.status == 1){
			$.magnificPopup.open({
				items: {src: '#torioki-receive-comp'},
				type: 'inline',
				callbacks: {
					close: function(){
						location.reload();
					}
				},
				modal: true
			}, 0);
		} else {
			$.magnificPopup.open({
				items: {src: '#torioki-error'},
				type: 'inline',
				modal: true
			}, 0);
			if(data.error != ""){
				$('#torioki-error .txt-comp').text(data.error)
			};
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		console.log(data);
		$.magnificPopup.open({
			items: {src: '#torioki-error'},
			type: 'inline',
			modal: true
		}, 0);
	});
};
*/

EPK.Delivery = EPK.Delivery || {};
EPK.Delivery.Popup = EPK.Delivery.Popup || {};

EPK.Delivery.Popup.$deliveryBtn = $('.delivery-reserve a');
EPK.Delivery.Popup.$ulDeliveryDaySelections = $('#ul_delivery_day_selections a');
EPK.Delivery.Popup.$btnBack = $('.modal-btn-back a');
EPK.Delivery.Popup.$selectDay = $('#select-day');
EPK.Delivery.Popup.$btnChangeDate = $('#select-day').closest('li');
EPK.Delivery.Popup.$selectTime = $('.select-time');
EPK.Delivery.Popup.$btnSendDeliveryForm = $('.modal-btn-send a');
EPK.Delivery.Popup.$perUnitTime = $('.per-unit-time');
EPK.Delivery.Popup.$reserveDatetimeSwiper = $('#reserve_datetime_swiper').swiper({
	centeredSlides: true,
	initialSlide: 0,
	slidesPerView: 'auto',
	loop: false,
	nextButton: '.ts-slider-fwd',
	prevButton: '.ts-slider-rev'
});

EPK.Delivery.Popup.availableDate = null;
EPK.Delivery.Popup.selectedDate = null;
EPK.Delivery.Popup.selectedStartTime = null;
EPK.Delivery.Popup.selectedEndTime = null;
EPK.Delivery.Popup.deliveryUrl = null;
EPK.Delivery.Popup.perUnitTime = 0;

EPK.Delivery.Popup.deliveryBaseUrl = '/delivery/monshin?pharmacy_id=';
EPK.Delivery.Popup.deliveryBaseClassGA = 'GA_mypage_submit_';

EPK.Delivery.Popup.popupIds = [
	'#modal-delivery-day',
	'#modal-delivery-time'
];

EPK.Delivery.Popup.open = function(index) {
	$.magnificPopup.close();
	$.magnificPopup.open({
		items: {src: EPK.Delivery.Popup.popupIds[index]},
		type: 'inline',
		modal: true,
		callbacks: {
			close: index === 1 ? function() {
				// 時間指定のスライダーを削除
				EPK.Delivery.Popup.deleteDateTimeSlide();
			} : null
		}
	}, 0);
};

EPK.Delivery.Popup.initSelector = function() {
	EPK.Delivery.Popup.$deliveryBtn = $('.delivery-reserve a');
	EPK.Delivery.Popup.$ulDeliveryDaySelections = $('#ul_delivery_day_selections a');
	EPK.Delivery.Popup.$btnBack = $('.modal-btn-back a');
	EPK.Delivery.Popup.$selectDay = $('#select-day');
	EPK.Delivery.Popup.$btnChangeDate = $('#select-day').closest('li');
	EPK.Delivery.Popup.$selectTime = $('.select-time');
	EPK.Delivery.Popup.$btnSendDeliveryForm = $('.modal-btn-send a');
}

EPK.Delivery.Popup.initialize = function() {
	EPK.Delivery.Popup.initSelector();

	EPK.Delivery.Popup.$deliveryBtn.off('click');
	EPK.Delivery.Popup.$deliveryBtn.on('click', function(e) {
		e.preventDefault();

		EPK.Delivery.Popup.availableDate = '';
		var shopId = $(this).data('pharmacy-id');
		EPK.Delivery.Popup.deliveryUrl = EPK.Delivery.Popup.deliveryBaseUrl + shopId;
		var dayOfWeekStr = [ "日", "月", "火", "水", "木", "金", "土" ];

		EPK.Delivery.Popup.$btnSendDeliveryForm.addClass(EPK.Delivery.Popup.deliveryBaseClassGA + shopId);

		$.each(EPK.MemberDelivery.deliveryAvailableTimes, function(shop_id, el) {
			if (shop_id == shopId) {
				EPK.Delivery.Popup.availableDate = el;
				return false;
			}
		});

		$('#ul_delivery_day_selections').find('li').remove();
		$.each(EPK.Delivery.Popup.availableDate['availabled_date'], function(date, bool) {
			var a, li;

			if (bool) {
				a = $('<a href="#" class="date-selection GA_mypage_selectdate" data-date="' + date.replace(/-/g, '') + '">');
			} else {
				a = $('<a href="#" class="date-selection GA_mypage_selectdate disabled" data-date="">');
			}

			var week = new Date(date).getDay();

			a.append(date.replace(/-/g, '').slice(4,6).replace(/^0/, '') + '/' + date.replace(/-/g, '').slice(6).replace(/^0/, ''))
			.append('<br>')
			.append($('<span>').append('（' + dayOfWeekStr[week] + '）'));

			li = $('<li>').append(a);

			$('#ul_delivery_day_selections').append(li);
		});

		EPK.Delivery.Popup.initPopup(0);
		EPK.Delivery.Popup.bindEvents();
	});
};

EPK.Delivery.Popup.initPopupSwiper = function () {
	EPK.Delivery.Popup.$selectTime.text('');
	EPK.Delivery.Popup.perUnitTime = 0;

	var year = EPK.Delivery.Popup.selectedDate.toString().slice(0, 4);
	var month = EPK.Delivery.Popup.selectedDate.toString().slice(4, 6);
	var day = EPK.Delivery.Popup.selectedDate.toString().slice(-2);
	var dateKey = year + '-' + month + '-' + day;

	var defaultSlide = 0;
	var sliderElement = '';

	$.each(EPK.Delivery.Popup.availableDate['time_table'][dateKey], function (index, el) {
		var startTime = ('0' + el.start_time.toString().replace(':', '')).slice(-4);
		var endTime = ('0' + el.end_time.toString().replace(':', '')).slice(-4);
		var inputDisabled = 'disabled';
		if (el.availabled_time) {
			if (defaultSlide == 0) {
				defaultSlide = index;
			}
			inputDisabled = '';
		}
		var inputEl = '<input type="radio" name="delivery-select-date" id="t' + index + '" data-time_index="' + index + '" data-start-time="' + startTime + '" data-end-time="' + endTime + '" ' + inputDisabled + '>';
		var timeEl = el.start_time + '<span>～</span>' + el.end_time;
		var labelEl = '<label for="t' + index + '" class="GA_mypage_selecttime">' + timeEl + '</label>';
		// スライダーを追加
		sliderElement += '<li class="swiper-slide" >' + inputEl + labelEl + '</li>';

		if (EPK.Delivery.Popup.perUnitTime == 0) {
			var d1 = new Date(dateKey.replace(/-/g, '/') + ' ' + startTime.slice(0, 2) + ':' + startTime.slice(2));
			var d2 = new Date(dateKey.replace(/-/g, '/') + ' ' + endTime.slice(0, 2) + ':' + endTime.slice(2));
			EPK.Delivery.Popup.perUnitTime = EPK.Delivery.Popup.minutesDistance(d1, d2);

			if (EPK.Delivery.Popup.perUnitTime < 60) {
				var text = EPK.Delivery.Popup.perUnitTime + '分';
			} else {
				var text = EPK.Delivery.Popup.perUnitTime / 60 + '時間';
			}
			EPK.Delivery.Popup.$perUnitTime.text(text);
		}
	});

	// スライダー追加、表示の初期スライド位置を設定
	EPK.Delivery.Popup.$reserveDatetimeSwiper.appendSlide(sliderElement);
	EPK.Delivery.Popup.$reserveDatetimeSwiper.slideTo(defaultSlide > 0 ? defaultSlide - 1 : defaultSlide, 0);

	$('[type=radio][id^=t]').on('click', function () {
		EPK.Delivery.Popup.$selectTime.text('').append($('<span>').append('○')).append($(this).next('label').text());
		EPK.Delivery.Popup.selectedStartTime = $(this).data('startTime');
		EPK.Delivery.Popup.selectedEndTime = $(this).data('endTime');
		EPK.Delivery.Popup.setDeliveryUrl();
	});
}
/**
 * 時間指定モーダルのスライダー要素を削除
 * @method deleteDateTimeSlide
 */
EPK.Delivery.Popup.deleteDateTimeSlide = function () {
	EPK.Delivery.Popup.$reserveDatetimeSwiper.removeAllSlides();
}
/**
 * 日付をフォーマットする
 * @param  {Date}   date     日付
 * @param  {String} [format] フォーマット
 * @return {String}          フォーマット済み日付
 */
EPK.Delivery.Popup.formatDate = function (date, format, hidden) {
	if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
	var weekArray = ['日', '月', '火', '水', '木', '金', '土'];
	format = format.replace(/YYYY/g, date.getFullYear());
	if (hidden) {
		format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
		format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
		format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
	} else {
		format = format.replace(/MM/g, (date.getMonth() + 1));
		format = format.replace(/DD/g, (date.getDate()));
		format = format.replace(/hh/g, date.getHours());
	}
	format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
	format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
	format = format.replace(/week/g, weekArray[date.getDay()]);

	if (format.match(/S/g)) {
		var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
		var length = format.match(/S/g).length;
		for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
	}
	return format;
};

EPK.Delivery.Popup.minutesDistance = function(src, dst) {
	var SECOND_MILLISECOND = 1000,
		MINUTE_MILLISECOND = 60 * SECOND_MILLISECOND,
		HOUR_MILLISECOND = 60 * MINUTE_MILLISECOND,
		DAY_MILLISECOND = 24 * HOUR_MILLISECOND,
		WEEK_MILLISECOND = 7 * DAY_MILLISECOND,
		YEAR_MILLISECOND = 365 * DAY_MILLISECOND;

	var deltaMinutes = dst.getTime() - src.getTime();

	return deltaMinutes / MINUTE_MILLISECOND;
};

EPK.Delivery.Popup.setDeliveryUrl = function() {
	var url = EPK.Delivery.Popup.deliveryUrl + '&selected_start_datetime=' + EPK.Delivery.Popup.selectedDate + EPK.Delivery.Popup.selectedStartTime + '&selected_end_datetime=' + EPK.Delivery.Popup.selectedDate + EPK.Delivery.Popup.selectedEndTime;

	EPK.Delivery.Popup.$btnSendDeliveryForm.prop('href', url);
};

EPK.Delivery.Popup.initPopup = function(popupId) {
	EPK.Delivery.Popup.open(popupId);
	EPK.Delivery.Popup.$btnSendDeliveryForm.prop('href', EPK.Delivery.Popup.deliveryUrl);
};

EPK.Delivery.Popup.bindEvents = function() {
	$('#ul_delivery_day_selections a').on('click', function(e) {
		e.preventDefault();
		if ($(this).hasClass('disabled')) {
			return false;
		}

		EPK.Delivery.Popup.selectedDate = $(this).data('date');
		var year = EPK.Delivery.Popup.selectedDate.toString().slice(0, 4);
		var month = EPK.Delivery.Popup.selectedDate.toString().slice(4, 6);
		var day = EPK.Delivery.Popup.selectedDate.toString().slice(-2);
		EPK.Delivery.Popup.$selectDay.find('.view-select-day').remove();
		EPK.Delivery.Popup.$selectDay.append($('<span class="view-select-day">').append(EPK.Delivery.Popup.formatDate(new Date(year, month-1, day), 'MM月DD日(week)', false)));
		EPK.Delivery.Popup.initPopup(1);
		EPK.Delivery.Popup.initPopupSwiper();
	});

	EPK.Delivery.Popup.$btnBack.on('click', function(e) {
		e.preventDefault();
		EPK.Delivery.Popup.initPopup(0);
	});

	EPK.Delivery.Popup.$btnChangeDate.on('click', function(e) {
		e.preventDefault();
		EPK.Delivery.Popup.initPopup(0);
	});

	EPK.Delivery.Popup.$btnSendDeliveryForm.on('click', function(e) {
		if (EPK.Delivery.Popup.$btnSendDeliveryForm.prop('href').indexOf('selected_start_datetime') === -1
			|| EPK.Delivery.Popup.$btnSendDeliveryForm.prop('href').indexOf('selected_end_datetime') === -1
		) {
			return false;
		}
	});
};

// 駐車場入口写真モーダル開く
EPK.Member.showParkingPhotoOpen = function(event){
	var shopId = $(event.target).data('shop_id');
	var url = '/shoho/monshin?pharmacy_id='+shopId+'&receive_mode=parking';
	$('#parking_receive_url').attr('href', url);

	$('.parking-img-slide ul').empty();
	for (var i = 0; i < 5; i++) {
		if (EPK.MemberParkingReceive.parkingImgList[shopId][i] !== undefined) {
			var li = $('<li class="swiper-slide"></li>');
			var img = $('<img>', {
				src: EPK.MemberParkingReceive.parkingImgList[shopId][i]['load_img'],
				alt: EPK.MemberParkingReceive.parkingImgList[shopId][i]['title'],
				"class": "lazyload"
			});
			img.attr('data-src', EPK.MemberParkingReceive.parkingImgList[shopId][i]['img_path']);
			var p = $("<p></p>", {
				"class": "parking-text"
			});
			p.text(EPK.MemberParkingReceive.parkingImgList[shopId][i]['comment']);
			li.append(img).append(p);
			$('.parking-img-slide ul').append(li)
		}
	}
	$.magnificPopup.open({
		items: {src: '#parking-photo-popup'},
		type: 'inline',
		modal: true
	}, 0);

	var swiper = $('#parking_receive_slider').swiper({
		centeredSlides: false,
		slidesPerView: 'auto',
		loop: false,
	});

	// スライダーを初期値からスタートさせる
	$('.parking-img-slide ul').css('transform', 'translate3d(0px, 0px, 0px)');
};

// 駐車場到着お知らせモーダル開く
EPK.Member.showSendParkingReachOpen = function(event){
	this.shopId = $(event.target).data("shop_id");
	this.reserveId = $(event.target).data("reserve_id");
	$.magnificPopup.open({
		items: {src: '#send-parking-reach-popup'},
		type: 'inline',
		modal: true,
		callbacks: {
			open: function() {
				$('#send-parking-reach-popup .y-btn, #parking-reach-fail-popup .y-btn').removeClass('disabled');
            }
		}
	}, 0);
};

// 駐車場到着連絡する
EPK.Member.sendParkingReach = function(){
	$('#send-parking-reach-popup .y-btn, #parking-reach-fail-popup .y-btn').addClass('disabled');

	var _this = this;
	$.ajax({
		url: '/api/shoho/parkingArrivalContact',
		type: 'POST',
		data: {
			shop_id: this.shopId,
			reserve_id: this.reserveId,
		},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data.status == 1){
			$.magnificPopup.open({
				items: {src: '#parking-reach-comp-popup'},
				type: 'inline',
				modal: true,
				callbacks: {
					open: function() {
						$('#send-parking-reach-popup .y-btn, #parking-reach-fail-popup .y-btn').removeClass('disabled');
		            }
				}
			}, 0);
		} else {
			$.magnificPopup.open({
				items: {src: '#parking-reach-fail-popup'},
				type: 'inline',
				modal: true,
				callbacks: {
					open: function() {
						$('#send-parking-reach-popup .y-btn, #parking-reach-fail-popup .y-btn').removeClass('disabled');
		            }
				}
			}, 0);
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {src: '#parking-reach-fail-popup'},
			type: 'inline',
			modal: true,
			callbacks: {
				open: function() {
					$('#send-parking-reach-popup .y-btn, #parking-reach-fail-popup .y-btn').removeClass('disabled');
	            }
			}
		}, 0);
	});
};
/**
 * 本部側EPARKクーポンの位置を変更
 * @method insertEparkCoupon
 */
EPK.Member.insertEparkCoupon = function() {
	var action = EPK.common.action;
	// クーポンかキャンペーンページの場合はEPARK本部のクーポン位置を変更
	if(action === 'campaign' || action === 'coupon') {
		var menu = document.querySelector('#mypage_menu');
		var epkCoupon = document.querySelector('#mypage-ad');
		if(menu && epkCoupon) {
			menu.parentElement.insertBefore(epkCoupon, menu);
		}
	}
};

/**
 * 数値のカンマ編集
 */
EPK.Member.numSeparate = function(num) {
	num = String(num);

	var strMinus = '';
	if (num.indexOf('-') > -1) {
		strMinus = '-';
	}

	num = num.replace(/-/g, '');
	var len = num.length;

	if (len > 3) {
		// 前半を引数に再帰呼び出し + 後半3桁
		return strMinus + this.numSeparate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
	} else {
		return strMinus + num;
	}
};

/**
 * #11987 予約ステータス、支払い履歴の追加（薬局・病院）
 * 予約履歴タブ切り替え
 * @method tabsSwiching
 */
EPK.Member.tabsSwiching = function() {

	var reserv,pastReserv,shopList;
	var tabsBtn = document.querySelectorAll('ul.select-tab a');

	var tabchange = function (event,id,shopList,hideclass,openclass,elem) {
		if (event.target.id == id) {
			//クリック時アクティブなら実行しない
			if (event.target.classList.contains('is-active')) return;
			if (shopList.length > 0) {
				for (var i = 0; i < shopList.length; i++) {

					//アクティブなショップリストを非表示
					if (shopList[i].classList.contains(hideclass)) {
						shopList[i].classList.add('is-disabled');
					}

					//非アクティブをアクティブに変更
					if (shopList[i].classList.contains(openclass) &&
					shopList[i].classList.contains('is-disabled')) {
						shopList[i].classList.remove('is-disabled');

						////タブのアクティブ切り替え
						event.target.classList.add('is-active');
						if (elem.classList.contains('is-active')) {
							elem.classList.remove('is-active');
						}
					}
				}
			}
		}
	}

	if (tabsBtn.length > 0) {

		[].forEach.call(tabsBtn,function(elem) {
			elem.addEventListener('click',function(event) {
				reserv = document.getElementById('reservation');
				pastReserv = document.getElementById('pastReservation');
				shopList = document.querySelectorAll('div.mypage-reserve-histry > div.shoplist');

				//予約履歴がないユーザー
				if (shopList.length == 0) {
					shopList = document.querySelectorAll('div.mypage-reserve-histry > div.no-reserve');
				}

				//予約中
				tabchange(event,'reservation',shopList,'pastreservetab','reservetab',pastReserv);
				//過去の予約
				tabchange(event,'pastReservation',shopList,'reservetab','pastreservetab',reserv);

			},false);
		})
	}
};

/**
 *  #11987 予約ステータス、支払い履歴の追加（薬局・病院）
 *  オンライン診療　もう一度予約するボタン
 *  EPK.MemberDisp.isSetReserveButton　がfalseの場合があるので切り離すさせる
 * @method renderRemoteColutionBtn
 */
EPK.Member.renderRemoteColutionBtn = function () {

	var targetElem = $('.loading_reservation_status.remote_consultation_reserve_btn');

	if (targetElem.length == 0) {
		return;
	}

	targetElem.each(function(index, elem){
		var $elem = $(elem);

		var hospital_id = $elem.attr('data-hospital_department_id');

		if (hospital_id) {
			var $template = $($elem.attr('data-template'));
			var label = $elem.attr('data-label') || "";
			var hospitalData = EPK.MemberDisp.targetHospitalDepartmentListSetReserveButton[hospital_id];
			var params = {
				hospital_id: hospital_id,
				hospitalDepartmentData: hospitalData,
				label: label,
			};
			var html = $template.render(params);
			$elem.replaceWith(function() {
				return html;
			});
		}
	});

}

/**
 *  外来診療　もう一度予約するボタン
 *  EPK.MemberDisp.isSetReserveButton　がfalseの場合があるので切り離すさせる
 * @method renderRemoteOutpatientBtn
 */
EPK.Member.renderRemoteOutpatientBtn = function () {

	var targetElem = $('.loading_reservation_status.remote_outpatient_reserve_btn');

	if (targetElem.length == 0) {
		return;
	}

	targetElem.each(function(index, elem){
		var $elem = $(elem);

		var hospital_id = $elem.attr('data-hospital_department_id');

		if (hospital_id) {
			var $template = $($elem.attr('data-template'));
			var label = $elem.attr('data-label') || "";
			var hospitalData = EPK.MemberDisp.targetHospitalOutpatientDepartmentListSetReserveButton[hospital_id];
			var params = {
				hospital_id: hospital_id,
				hospitalDepartmentData: hospitalData,
				label: label,
			};
			var html = $template.render(params);
			$elem.replaceWith(function() {
				return html;
			});
		}
	});

}

/**
 * #11987 予約ステータス、支払い履歴の追加（薬局・病院）
 * 受け取り完了イベント
 * @method receiveCompleate
 */
EPK.Member.receiveCompleate = function () {
	// this click要素
	var btnElem = this;
	var shopId = btnElem.getAttribute('data-shop_id');
	var reserveId = btnElem.getAttribute('data-reserve_id');

	//同ボタン連打制御
	if (btnElem.classList.contains('disabled')) {
		return;
	}
	btnElem.classList.add('disabled');

	$.ajax({
		type: 'POST',
		url: '/api/remote/shoho/status/complete',
		dataType : 'JSON',
		cache: false,
		data: {
			shop_id: shopId,
			reserve_id: reserveId
		}
	})
	.done(function(data) {
		//何もしない
	})
	.fail(function(data) {
		//何もしない
	})
	.always(function() {
		//画面をリロード
		location.reload();
	});
};

/**
 * #12258 WebCm
 * @method webcm_coupon_init
 * webcmクーポン
 */
EPK.Member.prototype.webcm_coupon_init = function(){

	var _this = this;
	var releaseBtn = document.getElementById('checkRelease');
	var changeBtn = document.getElementById('confirmChangeCoupon');
	var delBtn = document.querySelectorAll('div.delete-overlay > a.unusableDeleate');
	var useCouponBtn = document.getElementById('couponAccept');
	var detailBtn = document.getElementsByClassName('modal-coupon-tmpstock-detail-btn');
	var useCouponElem = document.getElementsByClassName('barcord-wrap');
	var useConfirmBtn = document.getElementById('useConfirmBtn');
	var useCompleateBtn = document.getElementById('useCompleateBtn');

	$.Deferred().resolve()
	.then(function() {
	}).then(function(data) {
		if (data) {
		// クーポンのチェック制御
			_this.webCmCheckCoupon();
		}
	});

	//選択解除ボタン
	if (releaseBtn) {
		releaseBtn.addEventListener('click',function () {
			_this.releaseBtnEvent(_this);
		});
	}

	//ポイントと交換する
	if (changeBtn) {
		changeBtn.addEventListener('click',function () {
			_this.showConfirmWebCmCouponUseOpen();
		})
	}
	// クーポンを利用する / 承諾して有効にする
	if (useCouponBtn) {
		useCouponBtn.addEventListener('click', function(event){
			_this.confirmWebCmCouponUse(event);
		});
	}
	//削除ボタン
	if (delBtn.length > 0) {
		_this.webCmCouponDelete(delBtn);
	}

	//クーポン詳細ボタン
	if (detailBtn.length > 0) {
		[].forEach.call(detailBtn,function (btnElem) {
			btnElem.addEventListener('click',function (event) {
				$.magnificPopup.open({
					items: {src: '#' + event.currentTarget.getAttribute('data-detail-modal-id')},
					type: 'inline',
					modal: true
				}, 0);
			});
		});
	}

	//クーポン利用画面
	if (useCouponElem.length > 0) {
		_this.initDisplayWebCmCoupon(useCouponElem);
	}
	//フロート利用完了ボタン
	if (useConfirmBtn) {
		useConfirmBtn.addEventListener('click',function () {
			$.magnificPopup.open({
				items: {src: '#useConfirmModal'},
				type: 'inline',
				modal: true
			}, 0);
		})
	}
	if (useCompleateBtn) {
		useCompleateBtn.addEventListener('click',function (event) {
			_this.useWebCmCoupon(event);
		})
	}
};

/**
 * #12258
 * @method initDisplayWebCmCoupon
 * WebCM　クーポン利用画面
 */
EPK.Member.prototype.initDisplayWebCmCoupon = function (brandCouponEls) {
	var len = brandCouponEls ? brandCouponEls.length : 0;
	if (!len) {
		return;
	}
	for (var couponIdx = 0; couponIdx < len; couponIdx++) {
		var couponElm = brandCouponEls[couponIdx];

		var countdownEl = couponElm.querySelector('.count-down-time');
		var couponImg = couponElm.querySelectorAll('.barcord-image img'); // 先頭の画像要素
		var changeBtn = couponElm.querySelectorAll('a.changebtn')

		if (couponImg.length > 0 && changeBtn.length > 0) {
			// 初期画像とボタンの設定
			this.changeWebCmCouponImg(couponImg,changeBtn)
		}

		if (!countdownEl) return;

		// クーポン分残り時間のカウントダウンタイマーの設定
		var nowDateStr = countdownEl.getAttribute("data-now");
		var expireDateTime = new Date(countdownEl.getAttribute("data-expire_datetime")).getTime();
		var nowDateTime = new Date(nowDateStr).getTime();
		// タイマー作成
		var timer = this.createWebCmCouponCountdownTimer(nowDateStr, expireDateTime - nowDateTime, countdownEl);
		// タイマー開始
		timer.startCountdown(500);
		this.couponCountdownTimerList.push(timer);
	}
};

/**
 * #12258 WebCm 新規クーポン企画
 * @method changeWebCmCouponImg
 * クーポン、バーコード、QRコードの切り替え
 */
EPK.Member.prototype.changeWebCmCouponImg = function (couponImg,changeBtn) {

	if(couponImg.length == 0 && changeBtn.length == 0) return;
	var QR = 'QRコード';
	var barcode = 'バーコード';

	[].forEach.call(changeBtn,function (btnElem) {
		btnElem.addEventListener('click',function (event) {
			var target = event.currentTarget.querySelector('span');
			var parentElem = event.currentTarget.closest('div.barcord-body');
			var imgElem = parentElem.querySelector('div.barcord-image img');
			var iconElem = event.currentTarget.querySelector('img');
			var imgPath,iconPath;
			if (target.innerHTML == QR) {
				imgPath = event.currentTarget.getAttribute('data-qrcode_image_path');
				iconPath = event.currentTarget.getAttribute('data-barcode_icon_path');
				imgElem.src = imgPath;
				iconElem.src = iconPath;
				target.innerHTML = barcode;
			} else if (target.innerHTML == barcode) {
				imgPath = event.currentTarget.getAttribute('data-barcode_image_path');
				iconPath = event.currentTarget.getAttribute('data-qrcode_icon_path');
				imgElem.src = imgPath;
				iconElem.src = iconPath;
				target.innerHTML = QR;
			}
		});
	})
};

/**
 * #12258 WebCMクーポンの残り時間表示
 * @method updateWebCmCouponCountdownTime
 * @param {Number} countDownMs 残り時間(ミリ秒)
 * @param {Element} elem 文字を表示するエレメント
 */
EPK.Member.prototype.updateWebCmCouponCountdownTime = function (countDownMs, elem) {
	if (!elem) {
		return;
	}
	var hhText = EPK.utils.formatMillisecond(countDownMs || 0, 'hh');
	var timeText = EPK.utils.formatMillisecond(countDownMs || 0, 'mm:ss');
	// 時間が00の場合は画面に表示しない
	timeText = (hhText !== '00' ? hhText + ':' : '') + timeText;
	elem.innerHTML = timeText;
};

/**
 * #12258 WebCm 新規クーポン企画 クーポンの時間切れ時の処理
 * @method timeoutWebCmCouponCountdown
 * @param {Element} elem 文字を表示するエレメント
 */
EPK.Member.prototype.timeoutWebCmCouponCountdown = function (elem) {
	if (elem) {

		var parentElem = elem.closest('section.barcord-wrap');
		// 表示時間を時間切れに変更する
		var countDownText = parentElem.querySelector('.countdown-text');
		var expireText = parentElem.querySelector('.expire-text');
		var barcode = parentElem.querySelector('div.barcord-image');
		var barcodeCss, barcodeMargin;
		var changeBtn = parentElem.querySelector('.changebtn');
		if (countDownText) {
			countDownText.style.display = 'none';
		}
		if (expireText && expireText.style.display == 'none') {
			expireText.style.display = '';
		}
		if (barcode) {
			barcodeCss = getComputedStyle(barcode , null );
			barcodeMargin = Number(barcodeCss.getPropertyValue('margin-right').replace(/px/g,''));
			if (barcodeMargin && barcodeMargin > 0) {
				barcode.style.marginRight = '0px';
			}
			for (var i = 0; i < barcode.children.length; i++) {
				if (barcode.children[i].style.display == '') {
					barcode.children[i].style.display = 'none';
				}
				if (barcode.children[i].classList.contains('coupon-timeout')) {
					barcode.children[i].style.display = '';
				}
			}
		}
		if (changeBtn) {
			changeBtn.style.display = 'none';
		}

		//表示されているクーポンがすべて期限が切れたときにモーダルを表示
		if (elem.classList.contains('in-time')) {
			elem.classList.remove('in-time');
			elem.classList.add('expire-time');
		}
		var expireTimeList = document.getElementsByClassName('expire-time');
		if (this.couponCountdownTimerList.length > 0 && expireTimeList.length > 0) {
			if (this.couponCountdownTimerList.length == expireTimeList.length) {
				$.magnificPopup.open({
					items: {src: '#modalCouponExpire'},
					type: 'inline',
					modal: true
				}, 0);
			}
		}
	}
};

/**
 * クーポン用のカウントダウンタイマー作成
 * @method createWebCmCouponCountdownTimer
 * @param {String} startTimeStr タイマー開始の日時文字列(Dateオブジェクトに変換できる文字列)
 * @param {Number} limitMillisecond 残り時間(ミリ秒)
 */
EPK.Member.prototype.createWebCmCouponCountdownTimer = function (startTimeStr, limitMillisecond, targetEl) {
	var param = {
		startTimeStr: startTimeStr || '',
		limitMillisecond: limitMillisecond || 0
	};
	var couponTimer = new CountdownTimer(param, targetEl);
	couponTimer.timeoutCountdown = this.timeoutWebCmCouponCountdown.bind(this);
	couponTimer.continueCountdown = this.updateWebCmCouponCountdownTime;
	return couponTimer;
};

/**
 * #12258
 * @method webCmCheckCoupon
 * webCM クーポン選択制御
 */
EPK.Member.prototype.webCmCheckCoupon = function () {
	var _this = this;
	var selectArea = document.querySelectorAll('.coupon-use-list .coupon-select-area');

	if (selectArea.length > 0) {
		[].forEach.call(selectArea,function (checkArea) {
			checkArea.addEventListener('click',function (event) {
				//他のクーポン選択中の制御
				var checkedList = document.querySelectorAll('.shop-couponlist input[type="checkbox"]:checked');
				if (checkedList.length > 0) {
					return;
				}

				var target = event.currentTarget;
				var targetStatus = target.querySelector('.status');
				if (targetStatus) {
					if (targetStatus.classList.contains('st-done')) {
						return;
					}	else if (targetStatus.classList.contains('st-select')) {
						//選択状態に切り替え格納する
						targetStatus.classList.add('st-selected');
						targetStatus.classList.remove('st-select');
						target.classList.add('checked');
						_this.webCmCheckedList.push(target);
					} else if (targetStatus.classList.contains('st-selected')) {
						//非選択状態に切り替える
						targetStatus.classList.add('st-select');
						targetStatus.classList.remove('st-selected');
						target.classList.remove('checked');

						//配列から削除
						for (var index = 0; index < _this.webCmCheckedList.length; index++) {
							if (_this.webCmCheckedList[index].getAttribute('target-id') == target.getAttribute('target-id')) {
								_this.webCmCheckedList.splice(index, 1);
								break;
							}
						}
					}
				}
				//他のクーポンのチェック制御
				_this.otherCouponControl(_this.webCmCheckedList);

				//ポイント
				_this.calcCouponPoint(target);
			})
		});
	}
};

/**
 * #12258 WebCM
 * @method otherCouponControl
 * クーポン一覧　他のクーポン、フロートボタン制御
 */
EPK.Member.prototype.otherCouponControl = function (webCmCheckedList) {
	var otherCoupon = document.querySelectorAll('.shop-couponlist input[type="checkbox"]');
	var webCmFadeUpFooter = document.getElementById('webcm-fade-up-footer');

	if (webCmCheckedList.length > 0) {
		EPK.Member.prototype.showWebCmCouponFadeUpFooter(webCmFadeUpFooter);
		if (otherCoupon.length > 0) {
			[].forEach.call(otherCoupon, function (checkbox) {
				checkbox.disabled = true;
			});
		}
	} else {
		EPK.Member.prototype.hideWebCmCouponFadeUpFooter(webCmFadeUpFooter);
		[].forEach.call(otherCoupon, function (checkbox) {
			checkbox.disabled = false;
		});
	}
};

/**
 * #12258 WebCM
 * @method releaseBtnEvent
 * クーポン一覧フロート　選択解除ボタン
 */
EPK.Member.prototype.releaseBtnEvent = function (_this) {
	for (var i = 0; i < _this.webCmCheckedList.length; i++) {
		if (_this.webCmCheckedList[i].querySelector('.status').classList.contains('st-selected')) {
			_this.webCmCheckedList[i].querySelector('.status').classList.add('st-select');
			_this.webCmCheckedList[i].querySelector('.status').classList.remove('st-selected');
		}
		if (_this.webCmCheckedList[i].classList.contains('checked')) {
			_this.webCmCheckedList[i].classList.remove('checked');
		}
		//残ポイントの増減
		EPK.Member.prototype.calcCouponPoint(_this.webCmCheckedList[i]);
	}
	_this.webCmCheckedList=[];
	if (_this.webCmCheckedList.length == 0) {
		_this.otherCouponControl(_this.webCmCheckedList);
	}
};

/**
 * #12258
 * @method showWebCmCouponFadeUpFooter
 * クーポン選択用のフロート固定フッターの表示/非表示
 */
EPK.Member.prototype.showWebCmCouponFadeUpFooter = function (webCmFadeUpFooter) {
	if(webCmFadeUpFooter) {
		$(webCmFadeUpFooter).stop().animate({'bottom' : '0px'}, 300);
	}
};
EPK.Member.prototype.hideWebCmCouponFadeUpFooter = function (webCmFadeUpFooter) {
	if(webCmFadeUpFooter) {
		$(webCmFadeUpFooter).stop().animate({'bottom' : '-100px'}, 300);
	}
};

/**
 * #12258  WebCm
 * @method calcCouponPoint
 * 残ポイントの下限増減
 */
EPK.Member.prototype.calcCouponPoint = function (checkBox) {
	if (checkBox) {
		//クーポン必要ポイント
		var necessaryPoint = Number(checkBox.getAttribute('data-point'));
		var remainingPoint = document.getElementById('remainingPoint');
		var calcPoint,textPoint;
		if (remainingPoint && remainingPoint.firstChild.textContent) {
			calcPoint = remainingPoint.firstChild.textContent;

			console.log(calcPoint.indexOf(','))
			if (calcPoint.indexOf(',')) {
				calcPoint = Number(calcPoint.replace(/,/g,""));
			} else {
				calcPoint = Number(remainingPoint.firstChild.textContent);
			}

			if (checkBox.classList.contains('checked')) {
				textPoint = calcPoint - necessaryPoint;
				remainingPoint.firstChild.textContent = textPoint.toLocaleString();
				remainingPoint.dataset.remainingPint = textPoint;
			} else {
				textPoint = calcPoint + necessaryPoint;
				remainingPoint.firstChild.textContent = textPoint.toLocaleString();
				remainingPoint.dataset.remainingPint = textPoint;
			}
		}
	}
};

/**
 * #12258  WebCm
 * @method webCmCouponDelete
 * 期限切れクーポン
 */
EPK.Member.prototype.webCmCouponDelete = function(delBtn){
	var _this = this;
	var couponID;
	[].forEach.call(delBtn,function(targetBtn) {
		targetBtn.addEventListener('click',function(event) {
			targetElem = event.currentTarget.closest('li');
			if (targetElem.querySelector('.coupon-select-area').length > 0) {
				couponID = targetElem.querySelector('.coupon-select-area').id;
			}
			_this.showWebCmCouponDeleteOpen(targetElem.querySelector('.coupon-select-area'), '#modal-coupon-delete');
		})
	});
};

/**
 * #12258  WebCm
 * @method showWebCmCouponDeleteOpen
 * 期限切れクーポン
 */
EPK.Member.prototype.showWebCmCouponDeleteOpen = function(target, modalId){
	this.couponId = target.getAttribute('target-id');
	this.deleteCouponUrl = '/coupon/delete/'+this.couponId;
	$.magnificPopup.open({
		items: {src: modalId},
		type: 'inline',
		modal: true
	}, 0);
};

/*
 * #12258 WebCM
 * @method showConfirmWebCmCouponUseOpen
 * 確認モーダル、URL等の生成
 */
EPK.Member.prototype.showConfirmWebCmCouponUseOpen = function(event){
	var _this = this;
	var addQuery = '';
	var tmpUrl = '';
	var checkedList = _this.webCmCheckedList;
	var usePoint = document.getElementById('useEparkPoint');
	var modalExpireMinutes = document.querySelectorAll('#confirmModal .tmp-coupon-expire-minutes');
	var expireMinutesArr = [];
	var totalPoint = 0;
	[].forEach.call(checkedList, function (checkbox) {
		if(tmpUrl == '') {
			tmpUrl = '/api/member/coupon/use_tmp_stock?'
		}
		if (addQuery == '') {
			addQuery += 'selected[]=' + checkbox.getAttribute('target-id');
		} else {
			addQuery += '&selected[]=' + checkbox.getAttribute('target-id');
		}
		expireMinutesArr.push(Number(checkbox.getAttribute('target-expire_minutes')))
		totalPoint += Number(checkbox.getAttribute('data-point'));
	});

	this.usedCouponUrl = tmpUrl + addQuery;
	this.totalPoint = totalPoint
	if (usePoint) {
		usePoint.innerHTML = this.totalPoint.toLocaleString();
	}
	if (modalExpireMinutes.length > 0 && expireMinutesArr.length > 0) {
		//モーダルに描画する分を取得する（期限切れ最小値）
		var minMinutes = expireMinutesArr[0];
		for (var i = 0; i < expireMinutesArr.length; i++) {
			minMinutes = Math.min(minMinutes, expireMinutesArr[i]);
		}
		for (var i = 0; i < modalExpireMinutes.length; i++) {
			modalExpireMinutes[i].innerHTML = minMinutes;
		}
	}

	$.magnificPopup.open({
		items: {src: '#confirmModal'},
		type: 'inline',
		modal: true
	}, 0);
};

/*
 * #12258
 * @method confirmWebCmCouponUse
 * 利用確認モーダルから利用画面への遷移
 */
EPK.Member.prototype.confirmWebCmCouponUse = function(event){

	var _this = this;
	var errorUrl = '/member/coupon/use_tmp_stock/error/';
	var result;

	$.ajax({
		url: this.usedCouponUrl,
		type: 'GET',
		data: {},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		//result[error: 0/1/2/3(0：正常/1～3エラー)]
		$.magnificPopup.close();
		if(data && data.result && String(data.result.error)) {
			result = data && data.result && data.result.error;
		}
		if(result == '0') { //正常

			location.href = '/member/coupon/used_tmp_stock';

		} else if(result == '1') { //エラー 1~3

			location.href = errorUrl + result;

		} else if(result == '2') { //エラー 1~3

			var action = errorUrl + result;
			var formHtml = "<form method='post' action='"+action+"' id='pointError' style='display: none;'>\n"
						+ "<input type='hidden' name='user_point' value='"+this.totalPoint+"'/>\n"
						+ "</form>";
			document.body.insertAdjacentHTML('beforeend',formHtml);
			document.getElementById('pointError').submit();

		} else if(result == '3') { //エラー 1~3
			location.href = errorUrl + result
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		location.href = errorUrl+'1';
	});
};

/**
 * #12258
 * @method useWebCmCoupon
 * webCm クーポン使用完了
 */
EPK.Member.prototype.useWebCmCoupon = function(event){

	var _this = this;
	var result;
	$.ajax({
		url: '/coupon/used_tmp_stock',
		type: 'POST',
		data: {},
		cache: false,
		dataType : "json",
		context: _this
	}).done(function(data, textStatus, jqXHR){
		$.magnificPopup.close();
		if(data && data.result && String(data.result.status)) {
			result = data && data.result && data.result.status;

			if (result == '0') {
				location.href = '/member/coupon/use_tmp_stock/error/1';
			} else if (result == '1') {
				location.href = '/member/coupon';
			}
		}
	}).fail( function(data) {
		$.magnificPopup.close();
		location.href = '/member/coupon/use_tmp_stock/error/1';
	});
};

/**
 * 外来診療予約のキャンセル
 * @method sendRemoteOutpatientCancel
 */
EPK.Member.sendCancelForRemoteOutpatient = function(event) {
	var _this = this;

	// id
	var id = $(event.target).data('reserve_id');

	// remove async
	$('.shoho-consul .async').remove();

	var $loadFilter = $('.loading-filter');
	$('body').prepend($loadFilter);
	$loadFilter.show();

	$.ajax({
		type: 'get',
		url: '/api/remote/outpatient/cancelModal',
		data: {
			'reserve_id': id
		},
		cache: false,
		dataType: 'html'
	})
	.done(function(html) {
		if (html) {
			$('.shoho-consul').prepend(html);

			$('.shoho-consul input[type=button]')
			.off('click.rocancel')
			.on('click.rocancel', function() {
				$('#remote-outpatient-cancel-01').show();

				// キャンセル - はい
				$('#remote-outpatient-cancel-01 .cfm-modal-btn.yes')
				.off('click.rocancel')
				.on('click.rocancel', function() {
					var __this = this;

					if ($(__this).hasClass('disabled')) {
						return;
					}
					$(__this).addClass('disabled');

					$.ajax({
						type: 'get',
						url: '/api/remote/outpatient/cancel',
						data: {
							'reserve_id': id
						},
						cache: false,
						dataType: 'json'
					})
					.done(function(data) {
						if (data.result.result == 'ok') {
							$('#remote-outpatient-cancel-01').hide();
							$('#remote-outpatient-cancel-02').show();
						} else {
							$('#remote-outpatient-cancel-03 .cfm-modal-txt').text(data.result.error_message);
							$('#remote-outpatient-cancel-01').hide();
							$('#remote-outpatient-cancel-03').show();
						}
					})
					.always(function() {
						$(__this).removeClass('disabled');
					});
				});

				// キャンセル - いいえ
				$('#remote-outpatient-cancel-01 .cfm-modal-btn.no')
				.off('click.rocancel')
				.on('click.rocancel', function() {
					$('#remote-outpatient-cancel-01').hide();
				});
			});

			$('.shoho-consul').show();
			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {src: '.shoho-consul'},
				type: 'inline',
				modal: true
			}, 0);
		}
	})
	.always(function() {
		$loadFilter.hide();
	});

};

EPK.Member.prototype.kmpoint_init = function(){

	var _this = this;
	var action = EPK.common.action;

	// ポイント残高表示
	_this.callKmPointSummary();

	// 「?」マーク_ポップアップ表示
	$('.help-icon').on('click', function(event){
		_this.openPopup('#point-howto-popup');
	});

	// ポイントTop・履歴_タブ切り替え
	var target_tag = (!EPK.common.isSP)? 'input[name="point_tab"]:radio' : '.tab';
	$(target_tag).on('click', function(event){
		_this.openTab(event);
	});

	// 画面遷移時のタブ切り替え
	var param = $(location).attr('search');
	if (param.match('tab=1')) {
		_this.linkOpenTab();
	}


	// ポイント履歴「もっと見る」
	$(document).on('click', '.more-history-list', function(event){
		EPK.Member.callKmpointHistory(event);
	});

	// 失効予定リスト「もっと見る」
	$(document).on('click', '.more-expired-list', function(event){
		EPK.Member.callKmPointExpiredList(event);
	});

	// 失効予定リスト_スクロール
	$('.point-expiration-btn a').on('click', function () {
		var speed = 600;
		var href = $(this).attr('href');
		var target = $(href === "#" || href === "" ? 'html' : href);
		var position = target.offset().top;
		$('html, body').animate({ scrollTop: position }, speed, 'swing');
		return false;
	});
};

/**
 * タブの切り替え
 */
EPK.Member.prototype.openTab = function(e){

	if (!EPK.common.isSP) {
		// PC_ポイントTOP・履歴画面の判定
		if (EPK.common.action == 'kmPoint') {
			var target = '.point-category';
		} else {
			var target = '.history-list';
		}
		var index = $('input[name="point_tab"]:radio').index(e.target);
	} else {
		// SP_ポイントTOP・履歴画面の判定
		if (EPK.common.action == 'kmPoint') {
			var target = '.point-category';
			$('.tab a').removeClass('is-active');
			$(e.target).addClass('is-active');
			var index = $('.tab a').index(e.target);
		} else {
			var target = '.history-list';
			$('.tab').removeClass('is-active');
			$(e.currentTarget).addClass('is-active');
			var index = $('.tab').index(e.currentTarget);
		}
	}

	$(target).removeClass('is-disabled');
	index = (index == 0)? index + 1 : index - 1;
	$(target).eq(index).addClass('is-disabled');
};

/**
 * 遷移時のタブの切り替え
 */
EPK.Member.prototype.linkOpenTab = function(){
	// 表示タブ
	var index = 1;

	if (!EPK.common.isSP) {
		// PC_ポイントTOP・履歴画面の判定
		if (EPK.common.action == 'kmPoint') {
			var target = '.point-category';
		} else {
			var target = '.history-list';
		}
		// タブの切り替え
		$('input[name="point_tab"]:eq(0)').prop('checked');
		$('input[name="point_tab"]:eq(1)').prop('checked', true);
	} else {
		// SP_ポイントTOP・履歴画面の判定
		if (EPK.common.action == 'kmPoint') {
			var tab_target = '.tab a';
			var target = '.point-category';
		} else {
			var tab_target = '.tab';
			var target = '.history-list';
		}
		// タブの切り替え
		$(tab_target).removeClass('is-active');
		$(tab_target).eq(index).addClass('is-active');
	}

	$(target).removeClass('is-disabled');
	$(target).eq(0).addClass('is-disabled');
};

/**
 * ポイント残高取得
 */
EPK.Member.prototype.callKmPointSummary = function(){
	var epk_point_flag = 0;
	var action = EPK.common.action;
	var header_summary = false;

	if (action == 'kmPointHistory') {
		// 履歴画面では不要
		return false;
	}

	// PC版_予約履歴画面の場合ヘッダーに表示
	if($('#set-km-point').length == 1){
		header_summary = true;
	}

	$.ajax({
		type: 'POST',
		url: '/api/kmpoint/summary',
		data: {},
		cache: false,
		dataType: 'json',
		timeout: 80000
	})
	.done(function(data) {
		var km_point = '-';

		if (data.result['status'] == 'success') {
			/** くすりの窓口ポイント **/
			if (data.result['km_point']) {
				km_point = data.result['km_point'].available_point;
				if (!km_point) {
					km_point = '0';
				}

				// PC版_予約履歴画面
				if (!EPK.common.isSP && header_summary){
					$('#set-km-point').append(km_point.toLocaleString());
				}

				// マイページ_ヘッダー部分に反映
				$('.my-kmpoint').text(km_point.toLocaleString());
				$('.my-kmpoint').append('<span class="unit">P</span>');

				// 失効予定ポイントの表示
				if (action == 'kmPoint') {
					lapse = data.result['km_point'].next_scheduled_expire_date;
					if (lapse) {
						// 直近の失効日
						var lapse_date = new Date(lapse);
						// 1ヶ月後の日付
						var future_date = new Date();
						future_date.setMonth(future_date.getMonth() + 1);
						if ((lapse_date.getTime() - future_date.getTime()) < 0) {
							// PC・SP毎の表示
							if (!EPK.common.isSP) {
								$('.status-box').append('<p class="alert-sub">1ヶ月以内に失効予定のポイントがあります</p>');
							} else {
								$('.point-lapse-label').append('<div class="caution-message">1ヶ月以内に失効予定のポイントがあります</div>');
							}
						}
						// 失効ポイント数
						var lapse_point = '-' + data.result['km_point'].scheduled_expire_point;
						$('#lapse-date').text('次回' + lapse_date.toLocaleDateString() + 'に失効予定');
						$('.lapse-point').text(lapse_point.toLocaleString());
						$('.lapse-point').append('<span class="unit">P</span>');
					} else {
						// 失効予定要素の削除
						$('.point-lapse-label').remove();
					}
				}
			}
		} else {
			// PC版_予約履歴画面
			if (!EPK.common.isSP && header_summary){
				$('#set-km-point').text('-');
			}

			// マイページ_ヘッダー部分に反映
			$('.my-kmpoint').text(km_point.toLocaleString());
			$('.my-kmpoint').append('<span class="unit">P</span>');
			if (action == 'kmPoint') {
				// 失効予定要素の削除
				$('.point-lapse-label').remove();
/*
				$.magnificPopup.open({
					items: {
						src: '#km-point-api-error',
						type: 'inline'
					}
				});
*/
			}
			console.log('kmPoint summary data is empty');
			return false;
		}
		return true;
	}).fail(function(data) {
		console.log('failed');
		console.log(data);
		return false;
	});
};

/**
 * ポイント履歴の取得
 */
EPK.Member.callKmpointHistory = function(event){
	var kmPointHistoryLoadMore = $(event.target).closest('.more-show-list');

	var offset      = kmPointHistoryLoadMore.data('offset');
	var count       = kmPointHistoryLoadMore.data('total_count');
	var page        = kmPointHistoryLoadMore.data('page');

	if (!$.isNumeric(offset)) {
		return false;
	}

	if (!$.isNumeric(count)) {
		return false;
	}

	if (!$.isNumeric(page)) {
		return false;
	}

	$.ajax({
		type: 'POST',
		url: '/member/kmpoint/history_more',
		data: {
			'offset'     : offset,
			'total_count': count,
			'page'       : page,
		},
		cache: false,
		dataType : 'html',
		timeout: 50000
	}).done(function(data){
		if (data.length > 0){
			$('.use-history-list').append(data);
			$('#more-show-no_' + page).remove();
			// PC_要素の移動
			if (!EPK.common.isSP) {
				$('.more-show-list').insertAfter('.historylist');
			}
		} else {
			console.log('data is empty');
		}

	}).fail(function(data){
		console.log('failed');
		console.log(data);
		return false;
	});
};

/**
 * 失効予定ポイントリストの取得
 */
EPK.Member.callKmPointExpiredList = function(event){
	var kmPointExpiredListLoadMore = $(event.target).closest('.more-expired-list');

	var offset      = kmPointExpiredListLoadMore.data('offset');
	var count       = kmPointExpiredListLoadMore.data('total_count');
	var page        = kmPointExpiredListLoadMore.data('page');

	if (!$.isNumeric(offset)) {
		return false;
	}

	if (!$.isNumeric(count)) {
		return false;
	}

	if (!$.isNumeric(page)) {
		return false;
	}

	$.ajax({
		type: 'POST',
		url: '/member/kmpoint/expired_more',
		data: {
			'offset'     : offset,
			'total_count': count,
			'page'       : page,
		},
		cache: false,
		dataType : 'html',
		timeout: 50000
	}).done(function(data){
		if(data.length > 0){
			if (!EPK.common.isSP) {
				$('.list').append(data);
			} else {
				$('.lapse-history-box').append(data);
			}
			$('#more-expired-show-no_' + page).remove();
			// PC_要素の移動
			if (!EPK.common.isSP) {
				$('.more-expired-list').insertAfter('.list');
			}
		}else{
			console.log('data is empty');
		}

	}).fail(function(data){
		console.log('failed');
		console.log(data);
		return false;
	});
};


// 位置情報の許可有無、チェックインAPI実行結果によりボタン出し分け
EPK.Member.checkin = function(argShowError) {
	var showError = true;
	var errorInfo = [
		"原因不明のエラーが発生しました。\n圏外でない場合は、端末の位置情報サービスの設定が有効になっているかご確認ください。",
		"位置情報が正しく計測されませんでした。\n圏外でない場合は、端末の位置情報サービスの設定が有効になっているかご確認ください。",
		"電波状況などで位置情報が取得できませんでした。電波状況のいいところで再度お試しください。",
		"位置情報の取得に時間がかかり過ぎてタイムアウトしました。電波状況のいいところで再度お試しください。"
	];

	var showAlertErrorMessage = function() {
		// エラーメッセージ
		var errorMessage = errorInfo[EPK.currentPlace.errorNo];
		// アラート表示
		var Browser = getBrowser();
		if (EPK.common.isSP ) {
			if (window.isiOS) {
				if ((EPK.currentPlace.errorNo == '1' || EPK.currentPlace.errorNo == '2') && Browser == 'Safari') {
					if (iosCheck()) { //iOS14以上は案内を表示
						errorMessage = errorMessage + "\n\n設定 > safari > 場所 の順に選択し\nkusurinomadoguchi.comを許可に変更の上、再読み込み後もう一度お試しください。";
						alert(errorMessage);
					} else {
						alert(errorMessage);
					}
				} else {
					alert(errorMessage);
				}
			} else {
				if ((EPK.currentPlace.errorNo == '1' || EPK.currentPlace.errorNo == '2') && Browser == 'Chrome') {
					errorMessage = errorMessage + "\n\nchromeのアドレスバー右のメニューから\n設定 > サイトの設定 > 位置情報 \nの順に選択しブロック中リストのくすりの窓口サイトの設定を許可へ変更しもう一度お試しください。"
					alert(errorMessage);
				} else {
					alert(errorMessage);
				}
			}
		} else {
			alert(errorMessage);
		}
	};

	var getBrowser = function () {
		var userAgent = window.navigator.userAgent.toLowerCase();
		if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
			return 'IE';
		} else if (userAgent.indexOf('edge') != -1 || userAgent.indexOf('edga') != -1) {
			return 'Edge';
		} else if (userAgent.indexOf('samsungbrowser') != -1){
			return 'Samsung';
		} else if (userAgent.indexOf('chrome') != -1) {
			return 'Chrome';
		} else if (userAgent.indexOf('safari') != -1) {
			if (userAgent.indexOf('crios') != -1) {
				return 'Chrome';
			} else {
				return 'Safari';
			}
		} else if (userAgent.indexOf('firefox') != -1) {
			return 'FireFox';
		} else if (userAgent.indexOf('opera') != -1) {
			return 'Opera';
		} else {
			return'other';
		}
	};

	var iosCheck = function () {
		var userAgent = window.navigator.userAgent.toLowerCase();
		if (/iphone/.test(userAgent)) {
			userAgent.match(/iphone os (\w+){1,3}/g);
			var iosver =(RegExp.$1.replace(/_/g, '')).slice(0,3);
			if (iosver >= 140) {
				//iPhone iOS14 以上
				return true;
			} else {
				//iPhone iOS14 以下
				return false;
			}
		}
	};

	var errorHandler = function(error) {
		// エラーコード(error.code)の番号
		// 0:UNKNOWN_ERROR				原因不明のエラー
		// 1:PERMISSION_DENIED			利用者が位置情報の取得を許可しなかった
		// 2:POSITION_UNAVAILABLE		電波状況などで位置情報が取得できなかった
		// 3:TIMEOUT					位置情報の取得に時間がかかり過ぎた…

		// エラー番号
		var errorNo = error.code;
		var inOneMinutes = new Date(new Date().getTime() + 1 * 60 * 1000);
		Cookies.set('errorNo', errorNo, { expires: inOneMinutes} );
		EPK.currentPlace.errorNo = errorNo;
		if (!showError) {
			console.log('現在位置が取得できませんでした。');
			console.log(errorInfo[errorNo]);
			console.log('errorNo:' + errorNo);

			// 位置情報の利用を許可する ボタン表示
			$('.loading_checkin_area').each(function(index, elem) {
				var $elem = $(elem);
				var $template = $($elem.attr('data-template'));
				var params = {
					currentPlace: false
				};
				var html = $template.render(params);
				$elem.replaceWith(function() {
					return html;
				});
			});
			// 位置情報の利用を許可する ボタン押下時に許可されていればリロード、されてなければアラート
			$('.current-place').off('click').on('click', function() {
				getCurrentPlace(function() {
					window.location.reload();
				}, true);
			});
			return;
		}
		showAlertErrorMessage();
	};

	// 位置情報を用いてAPI実行 チェックインボタン描画
	var renderCheckinButton =  function (position) {
		// 取得した位置情報はCookieに格納しない（画面リロードのたびに最新の位置を取得するため）
		var lat = 0;
		var lng = 0;
		if (position != null){
			var lat = position.coords.latitude;
			var lng = position.coords.longitude;
		}

		// チェックイン対象のshop_idを取得
		var shopIds = $('.loading_checkin_area').map(function(){
			return $(this).data('shop_id');
		}).get();

		if (shopIds.length === 0) {
			$('.loading_checkin_area').remove();
			return;
		}

		$.ajax({
			type: 'GET',
			url: '/api/checkin/possibility',
			data: {
				'shop_ids': shopIds,
				'lat': lat,
				'lng': lng
			},
			cache: false,
			dataType: 'json',
			timeout: 60000
		})
		.done(function(data) {
			var reserveCheckinList = EPK.MemberDisp.reserveNowCheckinList;
			Object.assign(reserveCheckinList, EPK.MemberDisp.reservedCheckinList);

			// 各予約にチェックインボタン表示
			$('.loading_checkin_area').each(function(index, elem) {
				var $elem = $(elem);
				var shopId = $elem.attr('data-shop_id');
				var reserveId = $elem.attr('data-reserve_id');
				var $template = $($elem.attr('data-template'));

				// APIレスポンスの中から対象shop_idのデータを取得
				var checkinDataEparkApi = data.find(function(value) {
					return value['shop_id'] == shopId;
				});

				if (typeof checkinDataEparkApi === 'undefined') {
					$elem.remove();
					return true;
				}

				// 予約・記帳データ無しの場合はボタン非表示
				if (checkinDataEparkApi['status'] == 1) {
					$elem.remove();
					return true;
				}

				// 予約履歴のチェックイン対象データ取得
				var reserveCheckin = reserveCheckinList[reserveId];

				if (typeof reserveCheckin === 'undefined') {
					$elem.remove();
					return true;
				}

				// LINE・おくすり手帳のmodal出し分け
				var modalPattern = 'line';
				var agreementClassificationStatus = $elem.attr('data-agreement_classification_status');
				var agreementAdvertisementPerationFlag = $elem.attr('data-agreement_advertisement_peration_flag');
				if ((agreementClassificationStatus == 1 && agreementAdvertisementPerationFlag == 1) || agreementClassificationStatus == 2) {
					// ウエルシア店舗以外はLINE＆手帳の
					if (!reserveCheckin['is_shop_brand_welcia']) {
						modalPattern = 'default';
					}
				}

				var params = {
					currentPlace: true,
					modalPattern: modalPattern,
					checkinDataEparkApi: checkinDataEparkApi
				};

				var html = $template.render(params);
				$elem.replaceWith(function() {
					return html;
				});
			});

			// チェックインボタン押下時のmodal表示
			$('.modal-checkin-open').off('click').on('click', function() {
				var shopId = $(this).attr('data-shop_id');
				var checkinModal = $(this).attr('data-modal_checkin');
				EPK.Member.checkinModal(shopId, checkinModal);
			});
			// チェックインテキストリンク、クリック時のLINE説明modal表示
			$('.line-text-link').off('click').on('click', function() {
				var shopId = $(this).attr('data-shop_id');
				EPK.Member.checkinLineGaTag(shopId);
				$.magnificPopup.open({
					items: {src: '#modal-checkin-line'},
					type: 'inline',
					modal: true
				}, 0);
				EPK.Member.checkinexpModal(shopId);
			});

			return true;
		}).fail(function(data) {
			console.log('failed');
			console.log(data);
			// チェックインボタンエリア削除
			$('.loading_checkin_area').remove();
			return false;
		});
	};

	// 位置情報取得
	var getCurrentPlace = function(callback, argShowError) {
		// エラー表示の切り替え
		// グローバル変数: showError に情報隔離
		if (argShowError != null) {
			showError = argShowError;
		}

		// ユーザーの端末がGeoLocation APIに対応しているかの判定
		// 対応している場合
		if (navigator.geolocation) {
			// 現在地を取得
			navigator.geolocation.getCurrentPosition(
				// [第1引数] 取得に成功した場合の関数
				function (position) {
					callback(position);
				},
				// [第2引数] 取得に失敗した場合の関数
				// 失敗してもチェックインボタンを表示するように設定
				function (position) {
					callback(null);
				},

				// [第3引数] オプション
				{
					"enableHighAccuracy": false,
					"timeout": 8000,
					"maximumAge": 2000,
				}
			);
		} else if(showError) {
			// エラーメッセージ
			var errorMessage = "現在地情報を取得できませんでした。<br>端末の設定より現在地情報サービスをオンにして下さい。";
			// アラート表示
			alert(errorMessage);
		}
	};

	getCurrentPlace(renderCheckinButton, argShowError);
};

// LINE・お薬手帳 チェックインのmodal表示
EPK.Member.checkinModal = function(shopId, checkinModal) {
	$('.line-link').attr('data-shop_id', shopId);

	$.magnificPopup.open({
		items: {src: '#modal-checkin-' + checkinModal},
		type: 'inline',
		modal: true
	}, 0);

	if (checkinModal == 'line'){
		var event_name = 'checkin_btn_line';
	}else{
		var event_name = 'checkin_btn_techoapp_line';
	}
	gtag('event', event_name, {
		page_location: 'member/reserve',
		shop_id: shopId,
		event_epark_id: !EPK.MemberDisp.memberGapid ? EPK.common.member_id : EPK.MemberDisp.memberGapid,
		member_id: !EPK.MemberDisp.memberGapid ? EPK.common.member_id : EPK.MemberDisp.memberGapid,
		user_id: !EPK.MemberDisp.memberGapid ? EPK.common.member_id : EPK.MemberDisp.memberGapid
	});

	$('.line-link').off('click').on('click', function() {
		// LINEでチェックインを行うリンクのGA定義
		var shopId = $(this).attr('data-shop_id');
		EPK.Member.checkinLineGaTag(shopId);

		// #25479 LINE モーダル「友だち追加してチェックイン」押下時のmodal表示
		EPK.Member.checkinexpModal(shopId);
	});
};

// #25479 LINEチェックイン説明POPUP画面
EPK.Member.checkinexpModal = function(shopId) {
	$('.modal-checkinexp-submit-link').attr('href', EPK.MemberDisp.lineMiniappUrl + shopId);
	$('.modal-checkinexp-submit-link').attr('data-shop_id', shopId);

	$.magnificPopup.open({
		items: {src: '#modal-checkinexp-line'},
		type: 'inline',
		modal: true
	}, 0);

	if (!EPK.Member.checkinexpSlider) {
		EPK.Member.checkinexpSlider = $(".checkinexp-slider").swiper({
			scrollbarHide: true,
			slidesPerView: 1,
			centeredSlides: true,
			spaceBetween: 0,
			grabCursor: true,
			preloadImages: true,
			paginationClickable: true,
			autoplay: '5000',
			pagination: ".swiper-pagination",
			loop: true
		});
	}

	$('.modal-checkinexp-submit-link').off('click').on('click', function() {
		// 「確認しました」ボタンのGA定義
		var shopId = $(this).attr('data-shop_id');
		EPK.Member.confirmedLineGaTag(shopId);
	});
};

// LINEでチェックインを行うリンクのGA定義
EPK.Member.checkinLineGaTag = function(shopId) {
	gtag('event', 'page_view_popup_friendsadd', {
		page_location: 'member/reserve',
		shop_id: shopId,
		event_epark_id: !EPK.MemberDisp.memberGapid ? EPK.common.member_id : EPK.MemberDisp.memberGapid,
		member_id: !EPK.MemberDisp.memberGapid ? EPK.common.member_id : EPK.MemberDisp.memberGapid,
		user_id: !EPK.MemberDisp.memberGapid ? EPK.common.member_id : EPK.MemberDisp.memberGapid
	});
};

// 「確認しました」ボタンのGA定義
EPK.Member.confirmedLineGaTag  = function(shopId) {
	gtag('event', 'page_view_popup', {
		page_location: 'member/reserve',
		shop_id: shopId,
		event_epark_id: !EPK.MemberDisp.memberGapid ? EPK.common.member_id : EPK.MemberDisp.memberGapid,
		member_id: !EPK.MemberDisp.memberGapid ? EPK.common.member_id : EPK.MemberDisp.memberGapid,
		user_id: !EPK.MemberDisp.memberGapid ? EPK.common.member_id : EPK.MemberDisp.memberGapid
	});
};
